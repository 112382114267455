import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Button, Text, Flex, Tooltip as ToolTip1, Center, Icon as Icon1, ButtonGroup, Modal, ModalContent, Wrap } from '@chakra-ui/react';
import { FiSettings } from 'react-icons/fi'
import { FaRoute, FaRegMap  } from 'react-icons/fa';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";
import L, { icon, divIcon } from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup, Polygon, Tooltip, Polyline, SVGOverlay, useMapEvents, LayersControl  } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import '../../css/App.css'
import 'leaflet/dist/leaflet.css';
import './images/marker.css'

import flag_start from "./images/flag_start.png"
import flag_end from "./images/flag_end.png"
import liefer_marker from "./images/liefer_marker.png"

const googleApiKey = "AIzaSyA_m6uDYjPcSKCSTBXp0_CAf0QN8PFpVqk";

const LocationFinderDummy = (props) => {
    const map = useMapEvents({

        click(e) {
            console.log(e.latlng);
            //props.LocationFinderCall(e.latlng)
        },
    });
    return null;
}

export default function MapScreen(props) {

    //const icon = L.icon({ iconUrl: "../../public/logo192.png" });
    const [RedLane, showRedLane] = useState(true);
    const [BlueLane, showBlueLane] = useState(true);
    const [TourData, setTourData] = useState([]);
    const [MapCenter, setMapCenter] = useState([50.82038549460329, 13.081370108643217]);
    const [EditCoords, setEditCoords] = useState([50.82038549460329, 13.081370108643217]);
    const [MapStyle, setMapStyle] = useState('');
    const [StyleSelect, setStyleSelect] = useState(false);

    const markerRef = useRef(null)
    const eventHandlers = useMemo(() => ({
        dragend(e) {
            var response = e.target.getLatLng()

            props.MapCall('newCords', response)
        },
    }))
    const StyleButtons = [
        { value: 'OSM_S', label: 'OSM Standart' },
        { value: 'OSM_D', label: 'OSM Deutsch' },
        { value: 'OSM_T', label: 'Open Topografisch' },
        { value: 'google_R', label: 'Google Streets' },
        { value: 'google_H', label: 'Google Hybrid' },
        { value: 'google_S', label: 'Google Satellit' },
        { value: 'google_T', label: 'Google Terain' },
      ];
    const FLAG_S = icon({
        iconUrl: flag_start,
        iconSize: [80, 80],
        iconAnchor: [38, 45]
    })
    const FLAG_E = icon({
        iconUrl: flag_end,
        iconSize: [80, 80],
        iconAnchor: [38, 45]
    })
    const LIEFER = icon({
        iconUrl: liefer_marker,
        iconSize: [80, 80],
        iconAnchor: [38, 45]
    })

    useEffect(() => {
        giveCenter();
    },[props.TourData, props.DeviceData, props.ShowDriver, props.singleLieferSt])

    useEffect(() => {
        if ((props.AbladeEdit || props.LieferEdit) && !props.editMap) {

            giveEditMarker();
        }
    }, [props.AbladeEdit, props.LieferEdit ]);

    useEffect(() => {
        giveMapStyle();
    }, [props.ActiveUser]);

    const LocationFinderCall = (coords) => {
        //console.log('MainComponent: ', coords)
        if (props.editMap){
            setEditCoords([coords.lat, coords.lng])
            props.MapCall('newCords', {lat: coords.lat, lng: coords.lng})
        }
    } 

    const RouteDataCreator = (route, routeData, color) => {

        if (route === undefined || routeData === undefined) return;
        /*
        const {KalmanFilter} = require('kalman-filter');

        const kFilter = new KalmanFilter({
            observation: 2,
            //dynamic: 'constant-speed'
        });

        //console.log(route);
        
        //if (color !== '#2485cc') { route = newRoute }
        if (color == '#2485cc') { route = newRoute }
        */

        return (
            <>
                <Polyline pathOptions={{ color: color }} positions={route} />

                {routeData.map((item, index) => {
                    if (index === 0) {

                        var TourStartIcon = L.divIcon({
                            className: 'custom-div-icon',
                            html: "<div class='flag-pole'></div><div class='flag-start'></div><div class='flag-id' style='background-color: " + color + "'></div>",
                            iconSize: [30, 40],
                            iconAnchor: [2, 40]
                        });

                        return (
                            <Marker position={[item.Latitude, item.Longitude]} icon={TourStartIcon} key={item.Position_ID}>
                                <Tooltip direction="top" offset={[0, -30]} className='tolltip' oppacity={2}>
                                    <Text fontSize='15px' fontWeight='bold'>Start der Tour</Text>
                                    <Text fontSize='15px' >{item.Time}</Text>
                                </Tooltip>
                            </Marker>
                        );
                    } else if (index === routeData.length - 1) {

                        var TourEndIcon = L.divIcon({
                            className: 'custom-div-icon',
                            html: "<div class='flag-pole'></div><div class='flag-end' ></div><div class='flag-id' style='background-color: " + color + "'></div>",
                            iconSize: [30, 40],
                            iconAnchor: [2, 40]
                        });

                        return (
                            <Marker position={[item.Latitude, item.Longitude]} icon={TourEndIcon} key={item.Position_ID}>
                                <Tooltip direction="top" offset={[0, -30]} className='tolltip' oppacity={2}>
                                    <Text fontSize='15px' fontWeight='bold'>Ende der Tour</Text>
                                    <Text fontSize='15px' >{item.Time}</Text>
                                </Tooltip>
                            </Marker>
                        );
                        }
                })}
            </>)
    }
    function giveMapStyle() {
        if (props.ActiveUser !== null && props.ActiveUser.User_Prefers != undefined && props.ActiveUser.User_Prefers.MapStyle != undefined ) {
            setMapStyle(props.ActiveUser.User_Prefers.MapStyle)
        } else {
            setMapStyle('OSM_D')
        }
    }
    function giveEditMarker() {

        if(props.AbladeEdit) {
            var Obj = props.AbladeEdit
        } else {
            var Obj = props.LieferEdit
        }

        if (Obj !== undefined && Obj.Latitude !== null && Obj.Longitude !== null ) {

            setMapCenter([Obj.Latitude, Obj.Longitude])

            setEditCoords([Obj.Latitude, Obj.Longitude])
        }
    }
    function giveCenter() {

        
        if (props.singleLieferSt !== false && props.singleLieferSt !== undefined) {

            setMapCenter([props.singleLieferSt.lat, props.singleLieferSt.long])

        } else if (props.ShowDriver && props.ShowDriverData.length !== 0 && 
            props.ShowDriverData[0].Last_Latitude !== null && props.ShowDriverData[0].Last_Longitude !== null) {

            setMapCenter([props.ShowDriverData[0].Last_Latitude, props.ShowDriverData[0].Last_Longitude])

        } else if (props.TourData !== undefined && props.TourData.length > 0 && props.TourData[0].Latitude !== null && props.TourData[0].Longitude !== null) {

            setMapCenter([props.TourData[0].Latitude, props.TourData[0].Longitude])

        } else if (props.DeviceData !== undefined && props.DeviceData.length > 0 && props.DeviceData[0].Latitude !== null && props.DeviceData[0].Longitude !== null) {

            setMapCenter([props.DeviceData[0].Latitude, props.DeviceData[0].Longitude])

        }
    }
    const MapLayerMask = () => {
        let content;
      
        switch (MapStyle) {

            case 'OSM_S':    
                content = <TileLayer key='OSM_S' attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            break;
            case 'OSM_D':
                content = <TileLayer key='OSM_D' attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.de/{z}/{x}/{y}.png" />
            break;
            case 'OSM_T': 
                content = <TileLayer key='OSM_T' attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png" />
            break;
            case 'google_R': 
                content = <ReactLeafletGoogleLayer key='google_R' apiKey={googleApiKey} type={'roadmap'} region='DE' language='de' />
            break;
            case 'google_H': 
                content = <ReactLeafletGoogleLayer key='google_H' apiKey={googleApiKey} type={'hybrid'} region='DE' language='de'/>
            break;
            case 'google_S': 
                content = <ReactLeafletGoogleLayer key='google_S' apiKey={googleApiKey} type={'satellite'} region='DE' language='de'/>
            break;
            case 'google_T': 
                content = <ReactLeafletGoogleLayer key='google_T' apiKey={googleApiKey} type={'terrain'} region='DE' language='de'/>
            break;
        }
        return content;
    }
    
    function EditMarkerComponent() {

        if(props.AbladeEdit) {
            var item = props.AbladeEdit

            var letter = "A"
            var textA = item.AbladeID
            var textB = item.Straße + ", " + item.PLZ + ", " + item.Ort

        } else {
            var item = props.LieferEdit

            var letter = "L"
            var textA = item.LieferID
            var textB = item.Name1 + ", " + item.Name2 + " | " + item.Strasse + ", " + item.PLZ + ", " + item.Ort
        }

        if(props.editMap) {
            var color = '#ed0f03'
        } else {
            var color = '#59acff'
        }

        var Mark = L.divIcon({
            className: 'custom-div-icon',
            html: "<div class='marker-pin' style='border-top-color: " + color + "'></div><div class='marker-circle' style='border-color: " + color + "'><p class='marker-text'>" + letter + "</p></div>",
            iconSize: [30, 42],
            iconAnchor: [18, 45]
        });
        return (
            
            <Marker position={EditCoords} icon={Mark} key={'editMarker'} popupAnchor={[100, -100]} draggable={props.editMap}
                ref={markerRef} eventHandlers={eventHandlers}
            >
                <Tooltip direction="top" offset={[5, -55]} className='tolltip' opacity={2} >
                    <Wrap margin={-2} borderColor='#666' borderWidth={3} borderRadius={5} overflow='hidden' p={1} width='300px'>
                        <Text fontSize='15px' fontWeight='bold' isTruncated>{textA}</Text>
                        <Text fontSize='15px' whiteSpace="pre-line" >{textB}</Text>
                    </Wrap>
                </Tooltip>

            </Marker>
        );
    }
    const DriverMarkerComponent = () => {

            var item = props.ShowDriverData[0]
            var color = '#59acff'
            var Mark = L.divIcon({
                className: 'custom-div-icon',
                html: "<div class='marker-pin' style='border-top-color: " + color + "'></div><div class='marker-circle' style='border-color: " + color + "'><p class='marker-text'>" + item.User_Name.slice(0,1) + "</p></div>",
                iconSize: [30, 42],
                iconAnchor: [18, 45]
            });

            return (
                
                <Marker position={[item.Last_Latitude, item.Last_Longitude]} icon={Mark} key={'driverMarker'} popupAnchor={[100, -100]}>
                     <Tooltip direction="top" offset={[2, -60]} 
                                        className='tooltip' opacity={2}>
                        <Flex h='60px' w='220px' margin={-2} padding={2} borderRadius={3} overflow='hidden'>
                        
                        {item.User_Img !== undefined && item.User_Img !== '' ? (
                            <Flex justifyContent='flex-start' alignItems='center' width='40px'>
                            <img src={props.server + '/API/icons/' + item.User_Img} alt="Profilbild"
                            style={{ borderRadius: 5, objectFit: 'cover', height: 32, width: 32, marginRight:5 }} />
                            </Flex>
                        ):(null)}
                            <Box flexDirection='column' display='flex'>
                                <Text fontSize='15px' fontWeight='bold' isTruncated>{item.User_Name}</Text>
                                <Text fontSize='15px' isTruncated>{item.Last_Time}</Text>
                            </Box>
                        </Flex>
                    </Tooltip>
                </Marker>
            );
    }

    const SingleLieferComponent = () => {
        return(
            <Marker
                position={[props.singleLieferSt.lat, props.singleLieferSt.long]} icon={LIEFER} key={props.singleLieferSt.lat + ',' + props.singleLieferSt.long}
            >
                <Tooltip direction="top" offset={[0, -30]} className='tolltip' opacity={2}>
                    <b>Zugestellt: </b><Text>{props.singleLieferSt.Zustell_Zeit}</Text>
                </Tooltip>
            </Marker>
        )
    }

    const DeviceListComponent = () => {

        return(<>

        {props.DeviceData.map((item, index) => {
            if (item.Latitude !== null && item.Longitude !== null ) {

                //console.log(item);
                var cords = [item.Latitude, item.Longitude];

                var color = '#ff5e2d'

                var Mark = L.divIcon({
                    className: 'custom-div-icon',
                    html: "<div class='marker-pin' style='border-top-color: " + color + "'></div><div class='marker-circle' style='border-color: " + color + "'><p class='marker-text'>" + (index + 1) + "</p></div>",
                    iconSize: [30, 42],
                    iconAnchor: [18, 45]
                });

                return (
                    <Marker position={cords} icon={Mark} key={item.AbladeID} popupAnchor={[100, -100]} //draggable={props.editMap}
                        ref={markerRef} eventHandlers={eventHandlers}
                    >
                        {(item.DeviceID !== false && item.DeviceID !== undefined) ? ( 
                            <Tooltip direction="top" offset={[5, -55]} className='tolltip' opacity={2}>
                                <Box h='100px' w='180px' margin={-2} borderColor='#666' borderWidth={3} borderRadius={5} overflow='hidden' p={1}>
                                    <Text fontSize='18px' isTruncated>{item.AbladeID}</Text>
                                    <Text fontSize='15px' isTruncated>{item.Device_Name}</Text>
                                    <Text fontSize='15px' isTruncated>{item.Last_User}</Text>
                                    <Text fontSize='15px' isTruncated>{item.Last_Time}</Text>
                                </Box>
                            </Tooltip>
                        ): null }
                    </Marker>
                );
            }
        })}
        </>)
    }

    const TourListComponent = () =>  {

        return(<>

        {props.TourData.map((item, index) => {
            if (item.Latitude !== null && item.Longitude !== null ) {

                var cords = [item.Latitude, item.Longitude];
                if (item.Updated !== 'D' && item.Updated !== 'Deleted') { var color = '#ff5e2d' } else { var color = '#969696' }

                var Mark = L.divIcon({
                    className: 'custom-div-icon',
                    html: "<div class='marker-pin' style='border-top-color: " + color + "'></div><div class='marker-circle' style='border-color: " + color + "'><p class='marker-text'>" + (index + 1) + "</p></div>",
                    iconSize: [30, 42],
                    iconAnchor: [18, 45]
                });

                return (
                    <Marker position={cords} icon={Mark} key={item.AbladeID} popupAnchor={[100, -100]} //draggable={props.editMap}
                        ref={markerRef} eventHandlers={eventHandlers}>

                        { item.Straße !== false && item.Straße !== undefined ? ( //check if Data is Device or AbladeSt.
                            <Tooltip direction="top" offset={[2, -60]}   className='tooltip' opacity={2}>
                                <Box h='120px'w='230px' margin={-2} padding={2} borderRadius={3} overflow='hidden'>
                                    <Text fontSize='17px' fontWeight='bold' isTruncated>{item.Straße}</Text>
                                    <Text fontSize='17px' isTruncated>{item.PLZ} {item.Ort}</Text>
                                    {item.Arrived === null && typeof item.TravelTime === 'string' 
                                    && item.Updated !== 'D' && item.Updated !== 'Deleted' ?  (
                                        <Flex><b>Geplante Ankunft:   </b><Text fontSize='12px'>{item.TravelTime.substr(0,5)} Uhr</Text></Flex>
                                    ) : (null)}
                                    {item.Arrived !== null ? (<Flex><b>Ankunft:</b><Text ml='2px' fontSize='12px'>{item.Arrived} Uhr</Text></Flex>): (null)}
                                    {item.Departed !== null ? (<Flex><b>Abfahrt:</b><Text ml='2px' fontSize='12px'>{item.Departed} Uhr</Text></Flex>): (null)}
                                </Box>
                            </Tooltip>
                        ): null}
                    </Marker>
                );
            }
        })}
        </>)
    }

    return(
        <Box 
        height='100%' 
        overflow="hidden" 
        position="relative">
        {/*<iframe width="100%" height="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=12.840614318847656%2C50.77858945556799%2C13.413963317871096%2C50.96967054723283&amp;layer=mapnik&amp;marker=50.819982629883334%2C13.081425726413727"></iframe>*/}

        { StyleSelect ? (
            <Center flexDirection='column' style={{ zIndex: 10, position: 'absolute', left: 10, top: 70 }} overflow='auto' h='160px' w='150px' borderRadius={5}
            mt={2} bg='#fff' border='2px solid #777' onMouseLeave={()=> setStyleSelect(false)}>

                {StyleButtons.map((button) => (
                    <Flex
                    key={button.value}
                    onClick={() => [setMapStyle(button.value), props.MapCall('setMap', button.value)]}
                    bg={null}
                    width='95%'
                    justifyContent='left'
                    cursor='pointer'
                    alignItems='center'
                    >
                    <Icon1 as={MapStyle === button.value ? IoMdRadioButtonOn : IoMdRadioButtonOff} color='#222' boxSize='18px'/>
                    <Text fontSize='14px'>{button.label}</Text>
                    </Flex>
                ))}

            </Center>
        ):(
            <Center style={{ zIndex: 10, position: 'absolute', left: 10, top: 70 }} h='50px' w='50px' borderRadius={5}
            mt={2} bg='#fff' border='2px solid #777' onMouseEnter={()=> setStyleSelect(true)} >
                <Icon1 as={ FaRegMap } color='#666' boxSize='30px'/>
            </Center>
        )}
            <MapContainer id='leafletComplette' key={MapCenter} center={MapCenter} zoom={14} style={{ zIndex: 1 }} >

                {MapLayerMask()}

                <Box style={{ zIndex: 400, position: 'absolute', right: 5, top: 5, display: 'flex', flexDirection: 'column' }}>  
                    {props.computeRoute ?
                        <ToolTip1 label={RedLane && props.AllowComputeRoute ? "Verstecke Plan Route" : "Zeige Plan Route"} placement='top' hasArrow bg='#666'>
                            <Button h='40px' w='40px' bg='#dd4402' _hover={{ bg: '#fc754e' }} onClick={() => showRedLane(!RedLane)}>
                                <Icon1 as={FaRoute} color='#fff' boxSize={25} />
                            </Button>
                        </ToolTip1>
                    : null}
                    {props.realRoute ?
                        <ToolTip1 label={BlueLane ? "Verstecke Fahrer Route" : "Zeige Fahrer Route"} placement='top' hasArrow bg='#666'>
                            <Button h='40px' w='40px' mt={2} bg='#0093ff' _hover={{ bg: '#55b4f9' }} onClick={() => showBlueLane(!BlueLane)}>
                                <Icon1 as={FaRoute} color='#fff' boxSize={25} />
                            </Button>
                        </ToolTip1>
                    : null}
                </Box>

                {RedLane && props.computeRoute ? RouteDataCreator(props.computeRoute.polyline, props.computeRoute.data, '#ed0f03') : null}
                {BlueLane && props.realRoute ? RouteDataCreator(props.realRoute.polyline, props.realRoute.data, '#2485cc') : null}

                {props.DeviceData !== undefined && props.DeviceData.length > 0 ? DeviceListComponent() : null}
                
                {props.TourData !== undefined && props.TourData.length > 0 ? TourListComponent() : null}

                {props.singleLieferSt !== false && props.singleLieferSt !== undefined ? SingleLieferComponent() : null}

                {props.ShowDriver && props.ShowDriverData.length !== 0 && 
                props.ShowDriverData[0].Last_Latitude !== null && props.ShowDriverData[0].Last_Longitude !== null 
                ? DriverMarkerComponent() : null}

                {props.AbladeEdit || props.LieferEdit ? EditMarkerComponent() : null }

            </MapContainer>

        </Box>
    )
}
