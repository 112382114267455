import React, { useState, useEffect, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Icon, Flex, useToast, Center, Input, Select, InputGroup, InputRightElement, VStack, Textarea, Alert } from '@chakra-ui/react'

import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerCloseButton, DrawerContent, } from '@chakra-ui/react'

import ChacraAlert from '../GlobalComponents/ChakraAlert';
import { ErrorToast, WarnToast, CheckToast } from '../GlobalComponents/CustomToast';

export default function CreateTeam(props) {

    //const [tableData, setTableData] = useState([]);
    const [NameText, setNameText] = useState('');
    const [chacraAlert, setChacraAlert] = useState({ isOpen: false, header: 'Header', text: 'Description', closeBtn: 'cancel', actBtn: 'action', onClose: null, onAction: null });
    const [change, setChange] = useState(false);

    const NameRef = useRef(null);
    const DescRef = useRef(null);

    const toast = useToast();
    const toastIdRef = React.useRef();

    useEffect(() => {
    }, []);

    async function SendTeamInsert(Params) {

        var body = { TeamParams: Params };

        var value = { target: 'Web_CreateTeam', params: '', body: body }
        var data = await props.EditUserCall('request', value, true)

        if (data === 'success') {
            toast({ render: () => (<CheckToast title='Teamdaten wurden gesendet' descr='Team wurde erstellt'/>), status: 'info', duration: 6000, isClosable: true, position: 'top-right' })
            CloseDrawer()
        } else if (data === 'exists'){
            toast({ render: () => ( <WarnToast 
                title='Teamnamen Konflikt' descr="Der Teamname existiert bereits für ein anderes Team!"/> ), 
                duration: 7000, isClosable: true, position: 'top-right' })
        } else {
            toast({ render: () => ( <ErrorToast 
                title='Teamdaten Speicherfehler' descr="Die angegebenen Teamdaten konnten nicht gespeichert werden!"/>), 
                status: 'error', duration: 7000, isClosable: true, position: 'top-right', })
        }

    }
    async function collectSavedData() {

        var Teamname = NameRef.current.value
        var Descr = DescRef.current.value;

        if (Teamname.length === 0) {
            toast({  render: () => ( <WarnToast title='Angaben unvollständig!' descr="Bitte geben Sie einen Teamnamen an!"/>), status: 'error', duration: 6000, isClosable: true, position: 'bottom-right', })
            return;
        }

        var TeamObj = {};
        TeamObj.Team_Name = Teamname;
        TeamObj.Team_Dscr = Descr;

        //console.log(TeamObj);
        SendTeamInsert(TeamObj)
    }

    function cancelChangeTest() {

        if (change) {
            ExitAlert();
        } else {
            props.EditUserCall('back')
        }
    }
    function ExitAlert() {
        var Alert = { ...chacraAlert };
        Alert.isOpen = true;
        Alert.header = 'Ungespeicherte Änderungen!';
        Alert.text = 'Möchten Sie wirklich alle Änderungen verwerfen?';
        Alert.closeBtn = 'Zurück'
        Alert.actBtn = 'Änderungen verwerfen'
        Alert.onClose = () => closeAlert();
        Alert.onAction = () => CloseDrawer();
        setChacraAlert(Alert)
    }
    function closeAlert() {
        var Alert = { ...chacraAlert }; Alert.isOpen = false; setChacraAlert(Alert)
    }
    function CloseDrawer() {
        var Alert = { ...chacraAlert }; Alert.isOpen = false;
        setChacraAlert(Alert);
        setChange(false);
        props.EditUserCall('back');
    }

    return (
        <>
            <ChacraAlert Alert={chacraAlert} />
            <Drawer
                isOpen={props.isOpen}
                size='md'
                onClose={() => cancelChangeTest()}
                closeOnEsc
            //placement='right'

            //finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent borderWidth={1} borderRadius={10} borderColor='#000' bgColor='#eee' m={1}>
                    <DrawerCloseButton onClick={() => cancelChangeTest()} />
                    <DrawerHeader fontSize={35} >Team Erstellen</DrawerHeader>

                    <DrawerBody p={4}>

                        <Flex bgColor='#fff' p={2} h='97%' flexDirection='column'>
                            <Flex flexDirection='column' overflow='hidden' mb={3}>
                                <Text fontSize='27' fontWeight='bold'>Team Name:</Text>
                                <Input type='text' w='100%' ref={NameRef} onChange={(text) => [setChange(true), setNameText(text)]} bgColor='#fff' />
                            </Flex>
                            <Flex flexDirection='column' overflow='hidden'>
                                <Text fontSize='27' fontWeight='bold'>Team Beschreibung</Text>
                                <Textarea w='100%' h='150px' ref={DescRef} onChange={() => setChange(true)} bgColor='#fff' resize="none" />
                            </Flex>

                        </Flex>

                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' colorScheme='teal' mr={3} onClick={() => cancelChangeTest()}
                        >Abbrechen</Button>
                        <Button colorScheme='teal' onClick={() => collectSavedData()}>Erstellen</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}