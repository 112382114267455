import React, { useState, useEffect } from "react"
import { Box, Text, Accordion, Button, Icon, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Center, Flex, Tooltip } from "@chakra-ui/react"
import { FiArrowDownCircle, FiArrowUpCircle, FiFile, FiPlus, FiSettings, FiAlertCircle } from "react-icons/fi"
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation, useSearchParams  } from 'react-router-dom';

import "../../css/App.css"
import APakete from "./APakete"
import ALieferstellen from "./ALieferstellen"

export default function AccordionTest(props) {
	
	const [expandedIndex, setExpandedIndex] = useState([]);
	const [expandedLieferIndex, setExpandedLieferIndex] = useState({});
	const [curentPaket, setcurentPaket] = useState('');

	const navigate = useNavigate(); //navigate to a certain path
	const [searchParams, setSearchParams] = useSearchParams(); //read and set query-params

	useEffect(() => {
		if(props.TourData.length !== 0) {
			readDefaultQuery();
		}
	}, [props.TourData])

	const readDefaultQuery = () => { //parses default query param

		const Ablade = searchParams.get('ablade');
		const Liefer = searchParams.get('liefer');
		const Paket = searchParams.get('paket');
		//console.log([Ablade, Liefer, Paket]);

		if(Ablade !== null && Ablade.length === 12){
			var Ablade_Idx = props.TourData.findIndex((item) => item.AbladeID == Ablade);
			if(Ablade_Idx !== -1){
				toggleItem(Ablade_Idx);

				if(Liefer !== null && props.TourData[Ablade_Idx].Lieferstellen !== null) {
					var Liefer_Idx = props.TourData[Ablade_Idx].Lieferstellen.findIndex((item) => item.LieferID == Liefer);
					if(Liefer_Idx !== -1){
						toggleLieferItem(Ablade_Idx, Liefer_Idx);

						if(Paket !== null && props.TourData[Ablade_Idx].Lieferstellen[Liefer_Idx] !== null) {
							var Paket_Idx = props.TourData[Ablade_Idx].Lieferstellen[Liefer_Idx].Pakete.findIndex((item) => item.Paketnummer == Paket);
							if(Paket_Idx !== -1){
								setcurentPaket(Paket);
							}
						}
					} 				
				} 
			}
		}
	}
//manuel Accordion handling test
	const toggleItem = (index) => {
    setExpandedIndex((prevExpandedIndex) =>
      prevExpandedIndex.includes(index)
        ? prevExpandedIndex.filter((item) => item !== index)
        //: [...prevExpandedIndex, index]
				: [index]
    );
  };

  const toggleLieferItem = (MainIndex, index) => {

		var prevExpandedLieferIndex = {...expandedLieferIndex};

		if(!prevExpandedLieferIndex[MainIndex]) {prevExpandedLieferIndex[MainIndex] = []};

      if (prevExpandedLieferIndex[MainIndex].includes(index)){
        var newSubArray = prevExpandedLieferIndex[MainIndex].filter((item) => item !== index)
				setLieferMarker('close')
			} else {
				
        var newSubArray = [...prevExpandedLieferIndex[MainIndex], index]
				setLieferMarker("opening", props.TourData[MainIndex].Lieferstellen[index])
			}
		prevExpandedLieferIndex[MainIndex] = newSubArray;
		setExpandedLieferIndex(prevExpandedLieferIndex);
  };

	function createPDF(LieferID, Manifest) {
		props.AccordionCall("createPDF", LieferID, Manifest)
	}
	function setLieferMarker(opening, lieferSt) {
		if (opening == "opening" && lieferSt.Latitude !== null && lieferSt.Longitude !== null) {
			var MarkerProps = { lat: lieferSt.Latitude, long: lieferSt.Longitude, Zustell_Zeit: lieferSt.LieferZeit }
			props.AccordionCall("setLiefer", MarkerProps)
		} else {
			var MarkerProps = false
			props.AccordionCall("setLiefer", MarkerProps)
		}
	}
	const paketCall = async (comand, value1,value2) => {
		switch (comand) {
			case 'setCurent':
				setcurentPaket(value1);
				break;
			default:
				break;
		}
	}
	const colorMaker = (Status, Mangel, Anzahl) => {

		if (Anzahl == 0) return '#ccc';//return '#ffadad';

		if (Status == 'none' ) return "#fff"

		if (Mangel) return '#fdd386'; 
		
		else return "#c2e9cc"

	}
	const lieferColorMaker = (Status, Mangel, Anzahl) => {

		if (Anzahl == 0) return '#ccc'; //return '#ffadad';

		if (Status == 'none' ) return "#e6e6e6"

		if (Mangel) return '#fdd386'; 
		
		else return "#c2e9cc"
	}

	const AbladeComponent = (item, index) => {
		return (
			<h2>
				<AccordionButton p={0} mt={1} borderWidth={1} borderRadius={3} borderColor="#000" _expanded={{ borderBottomStyle: "dashed", borderBottomRadius: 0 }} _hover={{ boxShadow: "0px 0px 15px #1786ff" }} onClick={() => toggleItem(index)}>
					<Center bg="#e77c78"
					minW={50} minH={50} text-align="center">
						<Text fontSize={32} fontWeight="bold">
							{index + 1}
						</Text>
					</Center>
					<Box flex="4" textAlign="left" bg={() => colorMaker(item.Status, item.Paket_Mangel !== undefined, item.Pakete_Anzahl)} pl={3} marginX='5px'>
						<Flex>
							<Text fontWeight="bold">
								{" "}
								{props.ShowCustomer && item.Lieferstellen[0] !== undefined ? "> " + item.Lieferstellen[0].Name1 + " | " : null}
								{item.Straße} | {item.PLZ} {item.Ort}{" "}
							</Text>	
							{item.AbladeHinweis !== '' ? (
								<Tooltip shouldWrapChildren label={item.AbladeHinweis} placement='top' hasArrow bg='#666' fontSize={16} offset={[0,0]}>
									<Icon marginLeft={2} marginTop='2px' as={FiAlertCircle} color="#0083c0" boxSize={'18px'} />
								</Tooltip>
							):(null)}
							</Flex>
						
						<Text>
							{item.Kennzeichen} || {item.Bezeichnung} || {item.Liefer_Anzahl} Lieferstelle/n || {item.Pakete_Anzahl} Paket/e || {item.Papier_Anzahl}x Papier{" "}
						</Text>
					</Box>
					<AccordionIcon />
				</AccordionButton>
			</h2>
		)
	}
	const LieferComponent = (item, index, indexL, isExpanded) => {
		return (
			<Box style={{ alignItems: "center", display: "flex", marginBottom: 2 }}>
				<Box
					bg={()=>lieferColorMaker(item.Status, item.Paket_Mangel !== undefined, item.Pakete_Anzahl)}
					p={1}
					//borderWidth={1}
					//borderRadius={3}
					borderColor="#000"
					borderTopWidth='3px'
					borderStyle='double'
					display="flex"
					width="100%"
					//_expanded={{ borderBottomStyle: 'dashed', borderBottomRadius: 0 }}
				>
					<Box flex="4" textAlign="left">
						<Flex>
							<Text fontWeight="bold">
								{item.Name1}; {item.Name2}; {item.Name3}; {item.Name4}
							</Text>
							{item.LieferHinweis !== null && item.LieferHinweis !== '' ? (
								<Tooltip shouldWrapChildren label={item.LieferHinweis} placement='top' hasArrow bg='#666' fontSize={16} offset={[0,0]}>
									<Icon marginLeft={2} marginTop='2px' as={FiAlertCircle} color="#0083c0" boxSize={'18px'} />
								</Tooltip>
							):(null)}
						</Flex>
						<Flex>
							{ /* item.Paket_Mangel ? (
								//<Icon as={FiAlertOctagon} color="red" bg='white' borderRadius={'15px'} boxSize='30px' />
								//<Box borderColor="red" borderRadius={'7px'} borderWidth='2px' bg='white' width='130px' textAlign='center' >
								//	<Text color='red' fontWeight='bold'>! keine Pakete !</Text>
								//</Box>
								<Box borderColor="red" borderRadius={'7px'} borderWidth='2px' bg='white' width='130px' textAlign='center' marginRight='5px' >
									<Text color='red' fontWeight='bold'>! Paket Mangel !</Text>
								</Box>
							):(null)*/ }
							<Text bgColor="white">
								{item.Strasse} {item.PLZ} {item.Ort} || Tel: {item.Telefon}
							</Text>
						</Flex>
					</Box>
					<Box marginX={2} flexWrap="wrap" display="flex" flexDirection="row" width="20%">
						<Text w="6vw">Zugestellt: </Text>
						{item.Status === "done" ? (
							<Button width="30px" height="25px" justifyContent="center" borderWidth="1px" borderColor="black" borderRadius={3} bgColor="#fff" marginLeft={1} onClick={() => createPDF(item.LieferID, item.Manifest)}>
								<Icon as={FiFile} color="#000" boxSize={19} />
							</Button>
						) : null}
						<ALieferstellen item={item} server={props.server} />
					</Box>
					<Box width="35px"></Box>
				</Box>
				<AccordionButton p={1} width="35px" height="35px" marginLeft={-39} justifyContent="center" borderWidth="1px" borderColor="black" borderRadius={3} bgColor="#fff" 
					//onClick={isExpanded ? () => setLieferMarker("closing", item) : () => setLieferMarker("opening", item)}
					onClick={() => toggleLieferItem(index, indexL)}
					>
					<Icon as={FiPlus} color="#000" boxSize={23} />
				</AccordionButton>
			</Box>
		)
	}
	const PaketComponent = (item, index) => {
		return (
			<Box height="45px" borderWidth={1} borderRadius={3} mb={2} borderColor="#000" textAlign="left" bg="white" key={item.Paketnummer}>
				<Box height={5} textAlign="left" bg="#fff" marginX={0.5}>
					<Text className="item-text-1">{item.Paketnummer}</Text>
				</Box>
				<Box height={5} textAlign="left" bg="#ddd" margin={0.5}>
					<Text className="item-text-1">{item.Paketnummer}</Text>
				</Box>
			</Box>
		)
	}
	if (props.ShowAblade) {
		return (
			<Accordion allowToggle p={1} index={expandedIndex}>
				<Text fontWeight="bold" textDecoration="underline" fontSize="18px">
					Abladestellen
				</Text>
				{props.TourData.map((item, index) => {
					return (
						<AccordionItem key={item.AbladeID}>
							{AbladeComponent(item, index)}
							<AccordionPanel p={1} bg="white" borderWidth={1} borderTopWidth={0} borderBottomRadius={3} borderColor="#000" >
								<Accordion index={expandedLieferIndex[index]} allowMultiple>
									<Text fontWeight="bold" textDecoration="underline" fontSize="18px">
										Lieferstellen
									</Text>
									{item.Lieferstellen.map((item, indexL) => {
										return (
											<AccordionItem key={item.LieferID}>
												{({ isExpanded }) => (
													<>
														{LieferComponent(item, index, indexL, isExpanded)}
														<AccordionPanel p={1} //</>bg="#ede2d8" px={1} borderWidth={1} borderBottomRadius={3} borderColor="#000" borderTopWidth={0}
															margin="2px" marginTop={0}>
															<Text fontWeight="bold" textDecoration="underline" fontSize="18px">
																Pakete
															</Text>

															<APakete LieferData={item.Pakete} ManifestNr={props.ManifestNr} server={props.server} 
																paketCall={paketCall} curent={curentPaket}/>
														</AccordionPanel>
													</>
												)}
											</AccordionItem>
										)
									})}
								</Accordion>
							</AccordionPanel>
						</AccordionItem>
					)
				})}
			</Accordion>
		)
	} else {
		return (
			<Accordion p={1} allowMultiple>
				<Text fontWeight="bold" textDecoration="underline" fontSize="18px">
					Lieferstellen
				</Text>
				{props.TourData.map((item, index) => {
					return (
						<Box flex="4" textAlign="left">
							<Text fontWeight="bold" color="#888">
								{index + 1} {item.Kennzeichen} | {item.Bezeichnung} | {item.Straße} | {item.PLZ} {item.Ort}
							</Text>
							{item.Lieferstellen.map((item, indexL) => {
								return (
									<AccordionItem key={item.LieferID}>
										{({ isExpanded }) => (
											<>
												{LieferComponent(item, index, indexL, isExpanded)}
												<AccordionPanel p={1} bg="#ede2d8" px={1} borderWidth={1} borderBottomRadius={3} borderColor="#000" borderTopWidth={0} margin="2px" marginTop={0}>
													<Text fontWeight="bold" textDecoration="underline" fontSize="18px">
														Pakete
													</Text>

													<APakete LieferData={item.Pakete} ManifestNr={props.ManifestNr} server={props.server} />
												</AccordionPanel>
											</>
										)}
									</AccordionItem>
								)
							})}
						</Box>
					)
				})}
			</Accordion>
		)
	}
}
