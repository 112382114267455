import React, { useState, useEffect, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Icon, Flex, useToast, Center, Input, Select, InputGroup, InputRightElement, VStack, Textarea, Alert, Spacer, Tooltip } from '@chakra-ui/react'

import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerCloseButton, DrawerContent, } from '@chakra-ui/react'
import { FiSettings, FiArrowRight, FiArrowLeft } from 'react-icons/fi'
import ChacraAlert from '../GlobalComponents/ChakraAlert';
import { ErrorToast, WarnToast, CheckToast } from '../GlobalComponents/CustomToast';

export default function EditRole(props) {
    //const [tableData, setTableData] = useState([]);
    const [NameText, setNameText] = useState('');
    const [chacraAlert, setChacraAlert] = useState({ isOpen: false, header: 'Header', text: 'Description', closeBtn: 'cancel', actBtn: 'action', onClose: null, onAction: null });
    const [change, setChange] = useState(false);
    const [RoleRights, setRoleRights] = useState([]);
    const [AllRights, setAllRights] = useState([]);

    const NameRef = useRef(null);
    const DescRef = useRef(null);

    const toast = useToast();
    const toastIdRef = React.useRef();

    useEffect(() => {
        if (props.isOpen) GET_Rights_to_Role(props.currentRole.Role_ID);
    }, [props.isOpen]);

    async function GET_Rights_to_Role(Id) {
        ////console.log('new Rights')
        var value = { target: 'Web_RightsToRole', params: '?RoleID=' + Id, body: {} }
        var data = await props.EditUserCall('request', value)

        //console.log(data);
        if (typeof data == 'object') {
            setRoleRights(data.RoleRights)
            setAllRights(data.AllRights)
        } else {
            alert('Abfrage Fehlgeschlagen!');
        }
    }

    function asignRights(Side, TeamID) {

        var TeamsLeft = [...RoleRights];
        var TeamsRight = [...AllRights];

        if (Side === 'in') {
            var Index = TeamsLeft.findIndex((elem) => elem.Right_ID === TeamID)
            if (Index !== -1) {
                TeamsRight.push(TeamsLeft[Index]);
                TeamsLeft.splice(Index, 1);
            }
        } else {
            var Ind = TeamsRight.findIndex((elem) => elem.Right_ID === TeamID)
            if (Ind !== -1) {
                TeamsLeft.push(TeamsRight[Ind]);
                TeamsRight.splice(Ind, 1);
            }
        }
        setAllRights(TeamsRight);
        setRoleRights(TeamsLeft);
        setChange(true);
    }

    async function SendRoleEdit(Params) {

        var body = { RoleChanges: Params };

        var value = { target: 'Web_SaveRoleChange', params: '', body: body }
        var data = await props.EditUserCall('request', value, true)

        if (data === 'success') {
            toast({ render: () => ( <CheckToast title='Rolendaten wurden gesendet' descr='Role wurde gespeichert'/>), status: 'info', duration: 6000, isClosable: true, position: 'top-right' })
            CloseDrawer()
        } else if (data === 'exists'){
            toast({ render: () => ( <WarnToast 
                title='Rolennamen Konflikt' descr="Der Rolenname existiert bereits für eine andere Role!"/> ), 
                duration: 7000, isClosable: true, position: 'top-right' })
        } else {
            toast({ render: () => ( <ErrorToast 
                title='Rolendaten Speicherfehler' descr="Die angegebenen Rolendaten konnten nicht gespeichert werden!"/>), 
                status: 'error', duration: 7000, isClosable: true, position: 'top-right', })
        }
    }
    async function SendRoleDelete(RoleID) {
        var value = { target: 'Web_UnsetRole', params: '?Role=' + RoleID, body: {} }
        var data = await props.EditUserCall('request', value, true)
        //var data = 'success'
        if (data === 'success') {
            toast({ render: () => ( <CheckToast title='Rolle gelöscht!' descr='Rolle wurde erfolgreich gelöscht'/> ), status: 'info', duration: 7000, isClosable: true, position: 'top-right' })
            CloseDrawer()
        } else {
            Alert('Verbundung Fehlgeschlagen!')
        }
    }
    async function collectSavedData() {

        var Rolename = NameRef.current.value;
        var Descr = DescRef.current.value;

        if (Rolename.length === 0) {
            toast({  render: () => ( <WarnToast title='Angaben unvollständig!' descr="Bitte geben Sie einen Rolennamen an!"/> ), status: 'error', duration: 6000, isClosable: true, position: 'bottom-right' })
            return;
        }
        var Right_IDs = []
        for (let I = 0; I < RoleRights.length; I++) {
            Right_IDs.push(RoleRights[I].Right_ID)
        }

        var RoleObj = {};
        RoleObj.Role_ID = props.currentRole.Role_ID
        RoleObj.Role_Name = Rolename;
        RoleObj.Role_Dscr = Descr;
        RoleObj.Role_Rights = Right_IDs;

        //console.log(RoleObj);
        SendRoleEdit(RoleObj)
    }

    function cancelChangeTest() {

        if (change) {
            ExitAlert();
        } else {
            props.EditUserCall('back')
        }
    }
    function ExitAlert() {
        var Alert = { ...chacraAlert };
        Alert.isOpen = true;
        Alert.header = 'Ungespeicherte Änderungen!';
        Alert.text = 'Möchten Sie wirklich alle Änderungen verwerfen?';
        Alert.closeBtn = 'Zurück'
        Alert.actBtn = 'Änderungen verwerfen'
        Alert.onClose = () => closeAlert();
        Alert.onAction = () => CloseDrawer();
        setChacraAlert(Alert)
    }
    function closeAlert() {
        var Alert = { ...chacraAlert }; Alert.isOpen = false; setChacraAlert(Alert)
    }
    function CloseDrawer() {
        var Alert = { ...chacraAlert }; Alert.isOpen = false;
        setChacraAlert(Alert);
        setChange(false);
        props.EditUserCall('back');
    }
    function confirmDeleteHandler() {
        var Alert = { ...chacraAlert };
        Alert.isOpen = true;
        Alert.header = 'Rolle "' + props.currentRole.Role_Name + '" löschen?';
        Alert.text = 'Möchten Sie diese Rolle wirklich löschen?';
        Alert.closeBtn = 'Abbrechen'
        Alert.actBtn = 'Löschen'
        Alert.onClose = () => closeAlert();
        Alert.onAction = () => SendRoleDelete(props.currentRole.Role_ID);
        setChacraAlert(Alert)
    }
    const TeamsComponent = (Right, Side) => {
        return (
            <Flex key={Right.Team_ID} borderColor='#000' borderWidth={1} borderRadius={5} flexDirection='row' p={1} m={1} justifyContent='flex-start' alignItems='center' w='97%' minHeight='35px' overflow='hidden'>

                <Button colorScheme='teal' mr='2px' size='xs' w='15px' onClick={() => asignRights(Side, Right.Right_ID)}>
                    <Icon as={Side === 'in' ? (FiArrowRight) : (FiArrowLeft)} color='#fff' boxSize='25px' />
                </Button>

                <Tooltip label={Right.Right_Dscr} hasArrow placement='top'>
                    <Text ml={10} fontWeight='bold' >{Right.Right_Dsgn}</Text>
                </Tooltip>

            </Flex>
        )
    }

    return (
        <>
            <ChacraAlert Alert={chacraAlert} />
            <Drawer
                isOpen={props.isOpen}
                size='lg'
                onClose={() => cancelChangeTest()}
                closeOnEsc
            //placement='right'

            //finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent borderWidth={1} borderRadius={10} borderColor='#000' bgColor='#eee' m={1} minHeight='700px'>
                    <DrawerCloseButton onClick={() => cancelChangeTest()} />
                    <DrawerHeader fontSize={35} >Role Bearbeiten</DrawerHeader>

                    <DrawerBody p={4}>

                        <Flex bgColor='#fff' p={2} h='97%' flexDirection='column'>
                            <Flex flexDirection='column' overflow='hidden' mb={3} h='15%'>
                                <Text fontSize='27' fontWeight='bold'>Role Name:</Text>
                                <Input type='text' w='100%' ref={NameRef} onChange={(text) => [setChange(true), setNameText(text)]} bgColor='#fff'
                                    defaultValue={props.currentRole.Role_Name} />
                            </Flex>
                            <Flex flexDirection='column' overflow='hidden' mb={3} h='25%'>
                                <Text fontSize='27' fontWeight='bold'>Role Beschreibung</Text>
                                <Textarea w='100%' h='150px' ref={DescRef} onChange={() => setChange(true)} bgColor='#fff' resize="none"
                                    defaultValue={props.currentRole.Role_Dscr} />
                            </Flex>
                            <Text fontSize='27' fontWeight='bold'>Rechte:</Text>
                            <Flex borderColor='#000' borderWidth={1} borderRadius={5} h='50%'>

                                <VStack borderColor='#000' borderRightWidth={1} w='50%' flexDirection='column' overflowY="auto">
                                    {
                                        RoleRights.map((elem) => {
                                            return TeamsComponent(elem, 'in')
                                        })
                                    }
                                </VStack>
                                <VStack w='50%' flexDirection='column' overflowY="auto" >

                                    {
                                        AllRights.map((elem) => {
                                            return TeamsComponent(elem, 'out')
                                        })
                                    }
                                </VStack >
                            </Flex>
                        </Flex>

                    </DrawerBody>
                    <DrawerFooter>
                        <Button colorScheme='red' mr={3} onClick={() => confirmDeleteHandler()}
                        >Löschen</Button>
                        <Spacer />
                        <Button variant='outline' colorScheme='teal' mr={3} onClick={() => cancelChangeTest()}
                        >Abbrechen</Button>
                        <Button colorScheme='teal' onClick={() => collectSavedData()}>Speichern</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}