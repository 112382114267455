import React, { useState, useEffect } from 'react';
import { Button, Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Icon, Image, Flex, Text, Center } from '@chakra-ui/react'
import BaseModal from './BaseModal';


export default function ConfirmModal(props) {
   
    useEffect(() => {
       
    },[])

    return (
        <BaseModal isOpen={props.isOpen} onClose={() => props.close()} 
        style={{maxWidth: '450px', minWidth: '450px', maxHeight: '150px', minHeight: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
               
                <Text fontSize='20px' fontWeight='bold'>{props.modalText}</Text>

                <Flex mt={3} Flex={1} width={'100%'} height={'50%'} justifyContent='space-evenly' alignItems='center'>

                    <Button colorScheme='twitter' onClick={() => props.close()}>{props.noButton}</Button>

                    <Button colorScheme='orange' onClick={() => props.yesFunc()} >{props.yesButton}</Button>

                </Flex>

        </BaseModal>
    );
}