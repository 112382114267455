// Modal.js
import React from 'react';
import '../css/modal.css';

const BaseModal = ({ isOpen, onClose, children, style }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" style={style} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default BaseModal;