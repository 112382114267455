import React, { useRef, useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Flex, useToast, Input, HStack, Tooltip, Icon, IconButton, Spacer  } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'
import { useNavigate, useSearchParams  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FaRegCalendarTimes, FaExchangeAlt } from 'react-icons/fa';
import DatePicker from 'react-date-picker';
import '../../css/DatePicker.css';
import '../../css/Calendar.css';
import '../../css/App.css'

import TableHead from '../../GlobalComponents/TableHead';
import WebsiteHeader from '../../GlobalComponents/WebsiteHeader';
import HistoryBox from '../HistoryBox';
import { ErrorToast, WarnToast, CheckToast } from '../../GlobalComponents/CustomToast'; 
import ConfirmModal from '../../GlobalComponents/ConfirnModal';
import BaseModal from '../../GlobalComponents/BaseModal';
import ImageBox from '../ImageBox';

export default function MultiPaketSuche(props) {

    const [tableData, setTableData] = useState([]);
    const [CurentIndex ,setCurentIndex] = useState(null);
    const [KundenIndex, setKundenIndex] = useState(null);
    const [history, setHistory] = useState([]);
    const [historyOpen, setHistoryOpen] = useState(false);
    
    const [ImageOpen, setImageOpen] = useState(false);
    const [ImageUri, setImageUri] = useState([]);
    const [ImageDir, setImageDir] = useState('');
    const [ImageRecipient, setImageRecipient] = useState('');

    const [ConfirmOpen, setConfirmOpen] = useState(false);
    const [SearchMulti, setSearchMulti] = useState(false);
    
    const [Date1, setDate1] = useState(new Date());
    const [Date2, setDate2] = useState(new Date());
    
    const navigate = useNavigate(); //navigate to a certain path
    const [searchParams, setSearchParams] = useSearchParams(); //read and set query-params
    const toast = useToast();
    const toastIdRef = React.useRef();
    const searchRef = useRef(null);

    useEffect(() => {

        _getsetPrevDay();

        window.addEventListener('keydown', handleKeyInput);
    
        return () => {
          window.removeEventListener('keydown', handleKeyInput);
        };
    }, []); 

    async function _getsetPrevDay(){

        var date = new Date()
        const day = date.getDay(); 
        const daysToSubtract = day === 0 ? 2 : day === 1 ? 3 : 1; 
        const previousWorkday = new Date(date); 
        previousWorkday.setDate(date.getDate() - daysToSubtract); 
        setDate1(previousWorkday);
        setDate2(date);
        //return [previousWorkday, date];
    }

    const columns = SearchMulti ? [
        { label: 'Paketnummer', accessor: 'Paketnummer', sortable: true, width: 40 },
        { label: 'Lieferschein', accessor: 'Lieferschein', sortable: true, width: 40 },
        
        { label: 'Manifest', accessor: 'Manifest', sortable: true, width: 30 },
        { label: 'Tour', accessor: 'Tour', sortable: true, width: 60 },
        { label: 'Fahrer', accessor: 'User_Name', sortable: true, width: 60 },

        { label: 'Kennzeichen', accessor: 'Kennzeichen', sortable: true, width: 60 },
        { label: 'Status', accessor: 'Status', sortable: true, width: 30 },
        { label: 'Zustell_Status', accessor: 'Zustell_Status', sortable: true, width: 60 },
        { label: 'Änderungsdatum', accessor: 'Änderungsdatum', sortable: true, width: 60 },
    ] : [
        { label: 'Paketnummer', accessor: 'Paketnummer', sortable: true, width: 40 },
        { label: 'Lieferschein', accessor: 'Lieferschein', sortable: true, width: 40 },
        
        { label: 'Manifest', accessor: 'Manifest', sortable: true, width: 30 },
        { label: 'Tour', accessor: 'Tour', sortable: true, width: 60 },
        { label: 'Fahrer', accessor: 'User_Name', sortable: true, width: 60 },

        { label: 'Kennzeichen', accessor: 'Kennzeichen', sortable: true, width: 60 },
        { label: 'Status', accessor: 'Status', sortable: true, width: 30 },
        { label: 'Zustell_Status', accessor: 'Zustell_Status', sortable: true, width: 60 },
        { label: 'Recipient', accessor: 'Recipient', sortable: true, width: 60 },
    ]
    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...tableData].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setTableData(sorted);
        }
    };
    async function APIConnector(target, params, json = {}) {

        var url = props.server + '/API/index.php/' + target + params;
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        json.Token = UserToken;
        //console.log(json)
        try {
            const response = await fetch(url, {
                method: 'POST',
                //headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Basic VHJhdmVsZXI6VGhpczEhSXMyIlRoZTPCp1Bhc3N3b3JkNCQ=' },
                body: JSON.stringify(json)
            });
            const results = await response.json();
            //console.log(results)
            if (results === 'invalid') {
                props.searchCall('logOut')
            } else if (results.AuthKey === 'noRight') {
                toast({ render: () => ( <WarnToast title='Berechtigungen Fehlerhaft' descr='Sie besitzen keine Berechtigung für diese Aktion!'/>), status: 'error', duration: 7000, isClosable: true, position: 'top', })
                props.searchCall('newRights', results.Rights)
            } else return results
        } catch (error) { console.log(error) }
    }
    async function _fetchPakete(text = "") {
        var start = Date1.getFullYear() + '-' + (Date1.getMonth() + 1) + '-' + Date1.getDate()
        var end = Date2.getFullYear() + '-' + (Date2.getMonth() + 1) + '-' + Date2.getDate()

        var search = text;
        var params = '?start=' + start + '&end=' + end + '&search=' + search

        var data = await APIConnector('Web_SearchPaket', params, {})
        if (Array.isArray(data)) {
            setTableData(data);
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' description="Die Serverantwort war fehlerhaft!" />), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }

    async function _fetchStuckPakete() {
        var start = Date1.getFullYear() + '-' + (Date1.getMonth() + 1) + '-' + Date1.getDate()
        var end = Date2.getFullYear() + '-' + (Date2.getMonth() + 1) + '-' + Date2.getDate()

        var params = '?start=' + start + '&end=' + end

        var data = await APIConnector('Web_SearchStuckPaket', params, {})
        //console.log(data);
        if (Array.isArray(data)) {
            setTableData(data);
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' description="Die Serverantwort war fehlerhaft!" />), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }
    async function _fetchHistory(PaketNr) {

        var params = '?PaketNr=' + PaketNr;

        var data = await APIConnector('GET_PaketHistory', params, {})
        if (Array.isArray(data)) {
            setHistory(data);
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Die Serverantwort war fehlerhaft!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }
    async function _requestDeletePaket(PaketNr, Manifest) {

        var body = { PaketNr , Manifest }
        console.log(body);
        var data = await APIConnector('Web_PaketDelete','', body)
        console.log(data);
        if (data === 'success') {
            return true;
        } else {
            toast({ render: () => ( <ErrorToast title='Paketlöschung abgebrochen!' descr="Dieses Paket kann nicht gelöscht werden!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
            setConfirmOpen(false);
        }
    }
    async function deletePaketHandler() {

        if( CurentIndex !== null){

            var PaketNr = tableData[CurentIndex].Paketnummer;
            var Manifest = tableData[CurentIndex].Manifest;

            var result = await _requestDeletePaket(PaketNr, Manifest);

            if(result) {
                var PaketData = [...tableData];
                PaketData.splice(CurentIndex, 1);
                setTableData(PaketData);
                setConfirmOpen(false); 
                setCurentIndex(null);
            } else {
                //error                
            }
        }
    }
    const FindPaketHandler = () => {

        if(SearchMulti) {

            _fetchStuckPakete();

        } else {
            
            var text = searchRef.current.value;

            if (text.length >= 3) {
                _fetchPakete(text)
            } else {
                toast({ render: () => ( <WarnToast title='Eingabe zu kurz!' descr='Bitte geben Sie mindestens 3 Zeichen an'/>), status: 'warning', duration: 2300, isClosable: true, position: 'top' })
            }
        }
    }
    const handleKeyInput = (event) => { //-- not fully implemented

        switch (event.key) {
            case 'h':
                selectHistory();
            break;
            case 'k':
                selectKundenData();
            break;
        
            default:
            break;
        }
    };
    const handleImage = (e, item) => { //open/close Image for Recipient

        e.stopPropagation();

        if (item.ImageUri !== null) {

            if(true //item.LieferZeit > '2024-09-20 12:00:00'

            ) {
                setImageDir('tour_images/' + item.Manifest + '/'// + item.LieferID + '/' 
                ); 
            } else {
                setImageDir('tmp/' ); 
            }
            setImageUri(JSON.parse(item.ImageUri))
            setImageRecipient(item.Recipient);
            setImageOpen(true)
        }
    }
    function ImageCall() {
        setImageOpen(false)
    }
    async function selectHistory() {
        //console.log(CurentIndex)
        if (CurentIndex !== null && !historyOpen) {
            await _fetchHistory(tableData[CurentIndex].Paketnummer);
            //setHistoryImage(Image)
            setHistoryOpen(true)
        }
    }
    async function selectKundenData() {
        //console.log(CurentIndex)
        if (CurentIndex !== null && KundenIndex == null) {

            setKundenIndex(CurentIndex);
        //} else {
        //    setKundenIndex(null);
        }
    }
    async function openManifestPaket() {

        if (CurentIndex !== null) {
            searchParams.set('manifest', tableData[CurentIndex].Manifest );
            searchParams.set('ablade', tableData[CurentIndex].AbladeID );
            searchParams.set('liefer', tableData[CurentIndex].LieferID );
            searchParams.set('paket', tableData[CurentIndex].Paketnummer );
            navigate(`/manifeste/view?${searchParams.toString()}`);
        }
    }
    async function openAbladeMove() {

        if (CurentIndex !== null) {
            searchParams.set('manifest', tableData[CurentIndex].Manifest );
            searchParams.set('ablade', tableData[CurentIndex].AbladeID );
            navigate(`/manifeste/edit/transfer?${searchParams.toString()}`);
        }
    }
    const HistoryCall = () => {

        setHistoryOpen(false)  
    }
    function handleSelect(index) {
        if (CurentIndex !== index) {
            setCurentIndex(index);
        } else {
            setCurentIndex(null);
        }
    }
    
    return (
        <Flex flexDirection='column'>
            <WebsiteHeader Text='TourPortal / Multi-Paket Suche' onClick={() => props.searchCall('back')} server={props.server} User={props.ActiveUser} />

            <Flex height='93vh' backgroundColor='#ccc' p={1} flexDirection='column'>

            <Box display='flex' bg='#fff' alignItems='center' justifyContent='flex-start' flexWrap='wrap'
                mb={1} borderWidth={1} borderColor='#000' borderRadius={3} py={1} >

                <Flex my={1} mx={3} width='780px' minHeight='40px' border='0px solid black' alignItems='center' justifyContent='space-evenly' borderRadius={6} bg='#1DA1F2' flexWrap='wrap'>
                    <IconButton bg='#1DA1F2' color='#fff' width='40px'
                        icon={<Icon as={FaRegCalendarTimes} color='#fff' boxSize={25}/>} onClick={() => _getsetPrevDay()} 
                        colorScheme='twitter' borderRadius='5px 0px 0px 5px'/>
                        <Text  color='#fff' fontWeight='semibold' fontSize={17} mx={1} >von:</Text>
                        <DatePicker onChange={setDate1} value={Date1} format='dd.MM.y' border='0px solid black'/>
                        <Text  color='#fff' fontWeight='semibold' fontSize={17} mx={1} >bis:</Text>
                        <DatePicker onChange={setDate2} value={Date2} format='dd.MM.y' border='0px solid black'/>
                        <IconButton bg='#1DA1F2' color='#fff' width='40px' ml={1}
                            icon={<Icon as={FaExchangeAlt} color='#fff' boxSize={22}/>} onClick={() => setSearchMulti(!SearchMulti)} 
                            colorScheme='twitter' borderRadius='5px'/>

                    { SearchMulti ? (
                        <Flex minWidth='26%' flex={1} h='35px' border='0px solid #1DA1F2' borderRadius={0} bg='#eee' mx={1} alignItems='center' justifyContent='center'>
                            <Text fontSize={16}>Wiederholungs-Pakete</Text>
                        </Flex>
                    ):(
                        <Input minWidth='26%' flex={1} h='35px' ref={searchRef} border='0px solid #1DA1F2' borderRadius={0} fontSize={17} bg='#fff' mx={1} 
                             //onKeyPress={e => { if (e.key === 'Enter') { FindPaketHandler() } }} 
                             placeholder='Suchtext...'
                        />
                    )}

                        <Button 
                        
                        onClick={(e) => [ e.stopPropagation(), FindPaketHandler()]} 
                        
                        
                        colorScheme='twitter' borderRadius='0px 5px 5px 0px'>Suche</Button>
                </Flex>  

                <HStack spacing={0.5} my={1} mx={3}>

                        <Button colorScheme='twitter' borderRadius={'5px 0px 0px 5px'} onClick={() => selectKundenData()} isDisabled={CurentIndex === null}>KundenDaten</Button>

                        <Button colorScheme='twitter' borderRadius={'0px 5px 5px 0px'} onClick={() => selectHistory()} isDisabled={CurentIndex === null}>Pakethistorie</Button>

                </HStack>
                <HStack spacing={0.5} my={1} mx={3}>
                    <Tooltip label="Paket in der Tour ansehen" placement='top' hasArrow bg='#666'>

                        <Button colorScheme='green' borderRadius={'5px 0px 0px 5px'} onClick={() => openManifestPaket()} isDisabled={CurentIndex === null}>gehe zu Manifest</Button>
                    </Tooltip>
                    <Tooltip label="Die zugehörige Abladestelle Verschieben" placement='top' hasArrow bg='#666'>

                        <Button colorScheme='green' borderRadius={'0px 5px 5px 0px'} onClick={() => openAbladeMove()} isDisabled={CurentIndex === null}>gehe zu Verschieben</Button>
                    </Tooltip>
                </HStack>
                <Box my={1} mx={3} >
                        <Button  colorScheme='orange' borderRadius={'5px'} onClick={() => setConfirmOpen(true)} isDisabled={CurentIndex === null}
                        >Paket Löschen</Button>
                </Box>
            </Box>
            <Box display='flex' height='100%' bg='#fff' flexDirection='column' alignItems='center' 
            justifyContent='flex-start' borderWidth={1} borderColor='#000' borderRadius={3} padding={2} overflow='auto'
            >

{/*tableData.map((row, index) => {
    return(

        <Flex h='50px' w='70%' bgColor='#ccc' mb='2px' justifyContent='space-between' key={row.Paketnummer}>
            <Text>{row.Paketnummer}</Text>

            <Text>{row.Manifest}</Text>
            <Text>{row.Tour}</Text>
            <Text>{row.User_Name}</Text>

            <Text>{row.Änderungsdatum}</Text>

            <Button onClick={()=> setKundenIndex(index)} ></Button>

        </Flex>

    )
})*/}
                <Table size="small" variant='striped' colorScheme='blackAlpha' width='80%'
                >
                    <TableHead {...{ columns, handleSorting }} />
                    <Tbody>
                        {tableData.map((row, index) => {
                            return (

                                <Tr key={row.Paketnummer} onClick={() => handleSelect(index)}
                                    bg={'#fff'}
                                    border={CurentIndex === index ? '3px dashed #3794ff' : null}
                                >
                                    {columns.map(({ accessor }) => {
                                        const tData = row[accessor];
                                        return (<React.Fragment key={accessor}>
                                            <Td textAlign="center" borderWidth='1.5px' borderColor='#e6e6e6' >
                                            {accessor === 'Recipient' && tData.length !== 0 ? (
                                                <Button borderWidth="1px" borderColor="black" borderRadius={3} bgColor="#fff" h='6' 
                                                onClick={(e) => handleImage(e, row)}
                                                >{tData}</Button>
                                                
                                            ) : (
                                                <Text noOfLines={1}>{tData}</Text>
                                            )}                                                
                                            </Td>
                                            <Td className="columnResizer" />
                                        </React.Fragment>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>

            </Flex>
            <BaseModal isOpen={KundenIndex !== null} onClose={() => setKundenIndex(null)}  
                style={{display: 'flex', flex: 1, alignItems: 'center', padding: 3, flexDirection: 'column', maxWidth: '700px', minWidth: '700px', maxHeight: '450px', minHeight: '100px', padding: 10 }}   
                >
                {KundenIndex !== null ? (
                    <Flex flexDirection='column' width='100%'>

                        <Flex>
                            <Text fontSize={17} fontWeight='bold' w='20%'>Name 1-4: </Text>
                            <Text fontSize={17} w='80%' >
                                {tableData[KundenIndex].Name1} | {tableData[KundenIndex].Name2} | {tableData[KundenIndex].Name3} | {tableData[KundenIndex].Name4}</Text>
                        </Flex>
                        <Flex>
                            <Text fontSize={17} fontWeight='bold' w='20%'>Adresse:</Text>
                            <Text fontSize={17} w='80%'>{tableData[KundenIndex].Strasse}, {tableData[KundenIndex].PLZ} {tableData[KundenIndex].Ort}</Text>
                        </Flex>
                        <Flex>
                            <Text fontSize={17} fontWeight='bold' w='20%'>Telefon:</Text>
                            <Text fontSize={17} w='80%'>{tableData[KundenIndex].Telefon}</Text>
                        </Flex>
                        <Flex>
                            <Text fontSize={17} fontWeight='bold' w='20%'>Konto:</Text>
                            <Text fontSize={17} w='80%'>{tableData[KundenIndex].Konto}</Text>
                        </Flex>

                    </Flex>
                ): (null)}
            </BaseModal>

            <ConfirmModal 
            isOpen={ConfirmOpen}
            close={() => setConfirmOpen(false)}
            modalText={CurentIndex !== null ? `Paket Nr.: ${tableData[CurentIndex]?.Paketnummer} wirklich löschen?` : ""}
            noButton="Abbrechen"
            yesButton="Löschen"
            yesFunc={() => deletePaketHandler()}
            />
            {ImageUri.length !== 0 ? (
                <ImageBox isOpen={ImageOpen} ImageCall={ImageCall} ImageDir={ImageDir} ImageUri={ImageUri} server={props.server} 
                curentReci={ImageRecipient} 
                />
            ) : (null)}

            <HistoryBox isOpen={historyOpen} server={props.server} paketCall={HistoryCall} 
            ImageDir={'tour_images/' + tableData[CurentIndex]?.Manifest + '/' //+ tableData[CurentIndex]?.LieferID +'/'
            } 
            Image={CurentIndex !== null ? (tableData[CurentIndex]?.Image) : ('')}
            curentPaket={CurentIndex !== null ? (tableData[CurentIndex]?.Image) : ('')}
            History={history} 
            />

        </Flex >
    );
}