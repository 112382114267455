import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Flex, useToast, Spacer, Icon } from '@chakra-ui/react'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react';

import { FiSettings } from 'react-icons/fi'
import { useTable, useSortBy } from 'react-table'
import Cookies from 'universal-cookie';
import WebsiteHeader from '../GlobalComponents/WebsiteHeader';
import EditTeam from './EditTeam';
import CreateTeam from './CreateTeam';
import CreateRole from './CreateRole';
import EditRole from './EditRole';
import { ErrorToast, WarnToast, CheckToast } from '../GlobalComponents/CustomToast';



export default function TeamsTable(props) {
    const [CurrentTeam, setCurrentTeam] = useState({});
    const [CurrentRole, setCurrentRole] = useState({});
    const [SubView, setSubView] = useState('');

    const [TeamsData, setTeamsData] = useState([]);
    const [RoleData, setRoleData] = useState([]);

    const toast = useToast();
    const toastIdRef = React.useRef();

    useEffect(() => {
        getTeamsWithUsers();
    }, []);
    async function EditUserCall(comand, value1, value2 = false) {
        if (comand === 'back') {
            setSubView('')
        }
        if (comand === 'request') {
            var data = await sendData(value1.target, value1.params, value1.body)

            if (value2) { getTeamsWithUsers(); }//keep in sync 
            return data;
        }
    }

    async function sendData(target, params, json = {}) {

        var url = props.server + '/API/index.php/' + target + params;
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        json.Token = UserToken;
        //console.log(json)
        try {
            const response = await fetch(url, {
                method: 'POST',
                //headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Basic VHJhdmVsZXI6VGhpczEhSXMyIlRoZTPCp1Bhc3N3b3JkNCQ=' },
                body: JSON.stringify(json)
            });
            const results = await response.json();
            if (results === 'invalid') {
                console.log(target)
                props.searchCall('logOut')
            } else if (results.AuthKey === 'noRight') {
                toast({  render: () => ( <WarnToast title='Berechtigungen Fehlerhaft' descr='Sie besitzen keine Berechtigung für diese Aktion!'/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
                props.searchCall('newRights', results.Rights)
            } else return results
        } catch (error) { console.log(error) }
    }
    async function getTeamsWithUsers() {
        var data = await sendData('Web_LoadAllTeams', '', {})

        if (Array.isArray(data)) {
            setTeamsData(data);
            //console.log(data);
            getRolesWithRights();
        }
    }
    async function getRolesWithRights() {
        var data = await sendData('Web_LoadAllRoles', '', {})

        if (Array.isArray(data)) {
            setRoleData(data);
            //console.log(data);
        }
    }
    function setEditTeam(index) {
        setCurrentTeam(TeamsData[index])
        setSubView('editTeam')
    }
    function setEditRole(index) {
        setCurrentRole(RoleData[index])
        setSubView('editRole')
    }
    const TeamComponent = (team, index) => {
        return (

            <AccordionButton bg='#fff' p={1} borderWidth={1} borderRadius={3} borderColor='#000'
                _expanded={{ borderBottomStyle: 'dashed', borderBottomRadius: 0 }}>
                <Text fontSize={20} >{team.Team_Name}</Text>

                <Spacer />

                <Text fontSize={17} mx={2}>{team.Team_Dscr}</Text>
                <AccordionIcon />
            </AccordionButton>

        )
    }
    const UserComponent = (user, index) => {
        return (
            <h2 key={user.User_ID}>
                <Box bg='#eee' p={2} m={1} borderWidth={1} borderRadius={3} borderColor='#000' alignItems={'center'} display='flex' overflow='auto'>

                    <Text>{user.User_Name} | {user.Role_Name}</Text>

                </Box>
            </h2>
        )
    }
    const RoleComponent = (role, index) => {
        return (
            <Flex key={role.Role_ID} width='90%' alignItems='center'>
                <Box bg='#fff' p={1} m={1} borderWidth={1} borderRadius={3} borderColor='#000' alignItems='center' w='100%' overflow='auto' display='flex'>

                    <Text fontSize={20} >{role.Role_Name}</Text>
                    <Spacer />
                    <Text fontSize={17} mx={2}>{role.Role_Dscr}</Text>

                </Box>
                <Button colorScheme='teal' mx='5px' w='20px' m={1} onClick={() => setEditRole(index)} mt='1px' isDisabled={!props.UserRights.user_manage}
                ><Icon as={FiSettings} color='#fff' boxSize={18} />
                </Button>

            </Flex>
        )
    }

    return (
        <Flex flexDirection='column'>
            <WebsiteHeader Text='TourPortal / Teams Verwalten' onClick={() => props.searchCall('back')} server={props.server} User={props.ActiveUser} />
            <Flex>
                <Flex borderWidth={1} borderRadius={5} m={1} borderColor='#000' bgColor='#eee' alignItems='flex-start' h='92vh' w='50%' justifyContent='center' overflow='auto' p={3} minHeight='650px'>


                    <Accordion allowToggle overflowY="auto" p={1} w='95%' //allowMultiple 
                    >
                        <Flex justifyContent='space-between'>
                            <Text fontWeight='bold' fontSize={25}>Teams</Text>
                            <Button colorScheme='teal' mx='5px' w='150px' onClick={() => setSubView('createTeam')} isDisabled={!props.UserRights.user_manage}>Team erstellen</Button>
                        </Flex>

                        {TeamsData.map((team, index) => {
                            return (
                                <Box display='flex' flexDirection='row' m={2} >
                                    <AccordionItem key={team.Team_ID} w='95%' >

                                        {TeamComponent(team, index)}

                                        <AccordionPanel p={1} bg='white' borderWidth={1} borderTopWidth={0} borderBottomRadius={3} borderColor='#000' >
                                            {team.Users.length !== 0 ? (
                                                team.Users.map((user, index) => { return UserComponent(user, index) })
                                            ) : (
                                                <Text textAlign='center' color='red'>Dieses Team hat keine Mitglieder!</Text>
                                            )}
                                        </AccordionPanel>

                                    </AccordionItem>
                                    <Button colorScheme='teal' mx='5px' w='20px' onClick={() => setEditTeam(index)} mt='1px'
                                        isDisabled={!props.UserRights.user_manage}
                                    ><Icon as={FiSettings} color='#fff' boxSize={18} />
                                    </Button>
                                </Box>
                            );
                        })}
                    </Accordion>


                </Flex>
                <Flex borderWidth={1} borderRadius={5} m={1} borderColor='#000' bgColor='#eee' alignItems='center' h='92vh' w='50%' justifyContent='flex-start' overflow='auto' p={3} minHeight='650px' flexDir='column'>

                    <Flex justifyContent='space-between' w='95%' mb={3}>
                        <Text fontWeight='bold' fontSize={25}>Rollen</Text>
                        <Button colorScheme='teal' mx='5px' w='150px' onClick={() => setSubView('createRole')} isDisabled={!props.UserRights.user_manage}
                        >Rolle erstellen</Button>
                    </Flex>

                    {RoleData.map((role, index) => { return RoleComponent(role, index) })}

                </Flex>
            </Flex>
            <CreateTeam EditUserCall={EditUserCall} isOpen={SubView === 'createTeam'} ActiveUser={props.ActiveUser} server={props.server} />
            <EditTeam EditUserCall={EditUserCall} isOpen={SubView === 'editTeam'} currentTeam={CurrentTeam} ActiveUser={props.ActiveUser} server={props.server} />
            <CreateRole EditUserCall={EditUserCall} isOpen={SubView === 'createRole'} ActiveUser={props.ActiveUser} server={props.server} />
            <EditRole EditUserCall={EditUserCall} isOpen={SubView === 'editRole'} currentRole={CurrentRole} ActiveUser={props.ActiveUser} server={props.server} />
        </Flex >
    );

}