import React, { useState, useEffect } from 'react';
import '../../css/App.css';
import { ErrorToast, WarnToast, InfoToast, CheckToast } from '../../GlobalComponents/CustomToast';
import {
    SimpleGrid, Box, useToast, Flex, Text, SliderThumb, Center, IconButton, Button,
    Textarea, Alert
} from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerCloseButton, DrawerContent, } from '@chakra-ui/react'
import ChacraAlert from '../../GlobalComponents/ChakraAlert';

import { BrowserRouter, Routes, Route, Link, Navigate, useNavigate, useLocation, useSearchParams  } from 'react-router-dom';
import MapScreen from '../../GlobalComponents/Map/MapScreen';
import WebsiteHeader from '../../GlobalComponents/WebsiteHeader';
import { latLng } from 'leaflet';

export default function EditLieferSt(props) {

    const [EditHinweis, setEditHinweis] = useState(false);
    const [EditMap, setEditMap] = useState(false);

    const [TempHinweis, setTempHinweis] = useState();
    const [tempLiefer, setTempLiefer] = useState({});

    const toast = useToast();

    const navigate = useNavigate(); //navigate to a certain path
    const [searchParams, setSearchParams] = useSearchParams(); //read and set query-params

    const [chacraAlert, setChacraAlert] = useState({ isOpen: false, header: 'Header', text: 'Description', closeBtn: 'cancel', actBtn: 'action', onClose: null, onAction: null });

    // ---- Style ----

    const SubHeaderStyle = {fontSize: '20px', fontWeight: 'bold'};

    //------------------------------------------
    useEffect(() => {

        if (props.LieferSt !== undefined) {
            //setTempLiefer();
            resetTempCords(JSON.parse(JSON.stringify(props.LieferSt)))
            setTempHinweis(props.LieferSt.LieferHinweis);
        } else {
            readQueryParams();
        }
        
    }, [props.LieferSt])

    function readQueryParams(){

        const Liefer = searchParams.get('liefer');
		if(Liefer !== null){
                props.EditLieferCall('setLIdx', Liefer);
		}
	}
    async function MapCall(comand, value1, value2) {
        if (comand === 'newCords') {
            changeTempCords(value1);
        }
        if (comand === 'setMap') { props.EditLieferCall('setLMap', value1) }
    }
    function resetHinweis() {

        const unlinkedLiefer = {...props.LieferSt};
        setTempHinweis(unlinkedLiefer.LieferHinweis);
        setEditHinweis(false);

    }
    function saveHinweis(type) {

        const keysToKeep = ["Manifest", "LieferID", "AbladeID", "LieferHash"];
        const reducedLiefer = Object.keys(tempLiefer).reduce((acc, key) => {
            if (keysToKeep.includes(key)) { acc[key] = tempLiefer[key]; }
            return acc;
        }, {});
        props.EditLieferCall('saveLHW', reducedLiefer, TempHinweis, type);
        setEditHinweis(false)
    }
    function changeTempCords(LatLng) {

        var Liefer = {...tempLiefer}
        Liefer.Latitude = LatLng.lat;
        Liefer.Longitude = LatLng.lng;
        setTempLiefer(Liefer);
    } 
    function resetTempCords(Lieferst = null) {

        var Liefer = Lieferst ? Lieferst : {...tempLiefer}

        Liefer.Latitude = props.LieferSt.M_Latitude;
        Liefer.Longitude = props.LieferSt.M_Longitude;
        setTempLiefer(Liefer);
        setEditMap(false);
    }
    function saveTempCords(){

        var Liefer = {...tempLiefer}
        var latlng = { lat: Liefer.Latitude, long: Liefer.Longitude };
        props.EditLieferCall('setLCoords', latlng);
        setEditMap(false);
    }
    //full LieferEdit page for later use
    if ( props.LieferSt !== undefined) return (
        <Flex flexDir='column'>
            <WebsiteHeader Text='TourPortal / Manifest Sortieren / Abladestelle Bearbeiten' onClick={() => props.EditLieferCall('close')} server={props.server} User={props.ActiveUser} />
            <Flex height='93vh' flexShrink={1} backgroundColor='#ccc' p={1} flexDirection='row'>
           
                <Flex flex={1.2} flexDirection='column' mr={1}>

                    <Flex flex={1} >

                        <Flex flex={1} borderWidth={1} borderRadius={3} borderColor='#000' 
                        mb={1} mr={1} bg='#fff' padding={2} overflow='auto' flexDirection='column'>

                            <Text fontSize={25} fontWeight={'bold'}>Lieferstelle: {props.LieferSt.LieferID}</Text>

                            <Flex flex={1} my={5} flexDirection='column' alignItems='flex-start' justifyContent='space-between'>        
                                <Text fontSize={20} >
                                        {props.LieferSt.Name1}, {props.LieferSt.Name2}, {props.LieferSt.Name3}, {props.LieferSt.Name4}
                                </Text>
                                <Text fontSize={20} color='#888'>Tel: {props.LieferSt.Telefon}</Text>
                                <Text fontSize={20}>
                                        {props.LieferSt.Strasse}, {props.LieferSt.PLZ} {props.LieferSt.Ort}
                                </Text>
                                <Text fontSize={20} color='#888'>Pakete: {props.LieferSt.Pakete_Anzahl} | Papier: {props.LieferSt.Papier_Anzahl}</Text>
                            </Flex>
                        </Flex>
                        <Flex flex={1} borderWidth={1} 
                        borderRadius={3} borderColor='#000' mb={1} bg='#fff' padding={2} overflow='auto' flexDirection='column' alignItems='center'>
                            <Text style={SubHeaderStyle} width='98%'>Lieferhinweis:</Text>

                            { EditHinweis ? (
                                <Flex m={1} flex={1} width='95%' alignItems='flex-end'>
                                    <Button m={2} colorScheme='twitter' onClick={() => resetHinweis() }>Abbrechen</Button>
                                    <Flex m={2} height='40px' >
                                        <Flex bgColor='#db6a00' alignItems='center' justifyContent='center' px={2} borderRadius='5px 0px 0px 5px' >
                                            <Text style={{color: '#fff', fontSize: '16px', fontWeight: 600}}>Speichern: </Text>
                                        </Flex>
                                        <Button onClick={() => saveHinweis('once')} colorScheme='orange' borderRadius='0px 0px 0px 0px' isDisabled={!props.UserRights.tour_manage} ml='2px'>Einmalig</Button>
                                        <Button onClick={() => saveHinweis('all')} colorScheme='orange' borderRadius='0px 5px 5px 0px' ml='2px'>Permanent</Button>
                                    </Flex>
                                </Flex>
                                ) : (
                                <Flex m={1} flex={1} width='93%'>
                                    <Button m={2} colorScheme='twitter' isDisabled={!props.UserRights.tour_manage} 
                                    onClick={() => setEditHinweis(true)}>Hinweis Bearbeiten</Button>
                                </Flex>
                            )}

                            <Textarea flex={1} my={5} maxWidth='90%' //value={props.LieferSt.Bezeichnung} 
                            resize='none' isDisabled={!EditHinweis} value={TempHinweis} onChange={(e)=> setTempHinweis(e.target.value)}
                            ></Textarea>

                        </Flex>
                        
                    </Flex>

                    <Flex flex={2.5} borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff' overflow='auto' flexDirection='column' >

                        <Flex flex={1} justifyContent='space-between'>
                        <Text style={SubHeaderStyle} ml={2} mt={2}>Koordinaten:</Text>
                            { EditMap ? (
                                <Flex m={1}>
                                    <Button m={2} colorScheme='twitter' onClick={() => resetTempCords() }>Abbrechen</Button>
                                   
                                    <Button m={2}  colorScheme='orange' isDisabled={!props.UserRights.tour_manage} ml='2px' 
                                    onClick={() => saveTempCords()}>Position Speichern</Button>
                                </Flex>
                                ) : (
                                <Flex m={1}>
                                    <Button m={2} colorScheme='twitter' isDisabled={!props.UserRights.tour_manage} 
                                    onClick={() => setEditMap(true)}>Position Bearbeiten</Button>
                                </Flex>
                            )}
                        </Flex>

                    { tempLiefer.AbladeID !== undefined ? (

                        <MapScreen LieferEdit={tempLiefer} Route={[]} RouteData={[]} editMap={EditMap} MapCall={MapCall} ActiveUser={props.ActiveUser}/>
                        
                    ):(null)}
                    </Flex>

                </Flex>

                <Flex flex={1} borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff' padding={2} overflow='auto' flexDirection='column' minWidth='400px'>
                    <Flex justifyContent='space-between'>
                      

                    </Flex>
                </Flex>
            </Flex>
        </Flex >
    )
    if ( props.LieferSt !== undefined  && props.LieferSt === 'debug' ) return (

        <Drawer
            isOpen={true}
            size='md'
            onClose={() => props.EditLieferCall('close')}
            closeOnEsc
        //placement='right'

        //finalFocusRef={btnRef}
        >
            <DrawerOverlay />
            <DrawerContent borderWidth={1} borderRadius={10} borderColor='#000' bgColor='#eee' m={1}>
                <DrawerCloseButton onClick={() => props.EditLieferCall('close')} />
                <DrawerHeader fontSize={35} >Lieferstelle: {props.LieferSt.LieferID}</DrawerHeader>

                <DrawerBody p={4}>

                    <Flex flex={1} borderWidth={1} borderRadius={3} borderColor='#000' 
                        mb={1} mr={1} bg='#fff' padding={2} overflow='auto' flexDirection='column' alignItems='flex-start' justifyContent='space-between'>

                        <Text fontSize={20} >
                                {props.LieferSt.Name1}, {props.LieferSt.Name2}, {props.LieferSt.Name3}, {props.LieferSt.Name4}
                        </Text>
                        <Text fontSize={20} color='#888'>Tel: {props.LieferSt.Telefon}</Text>
                        <Text fontSize={20}>
                                {props.LieferSt.Strasse}, {props.LieferSt.PLZ} {props.LieferSt.Ort}
                        </Text>
                        <Text fontSize={20} color='#888'>Pakete: {props.LieferSt.Pakete_Anzahl} | Papier: {props.LieferSt.Papier_Anzahl}</Text>

                    </Flex>

                    <Flex flex={1} borderWidth={1} 
                        borderRadius={3} borderColor='#000' mb={1} bg='#fff' padding={2} overflow='auto' flexDirection='column' alignItems='center'>
                        <Text style={SubHeaderStyle} width='98%'>Lieferhinweis:</Text>

                        <Textarea flex={1} my={5} maxWidth='90%' //value={props.LieferSt.Bezeichnung} 
                        resize='none' isDisabled={!true} value={TempHinweis} onChange={(e)=> setTempHinweis(e.target.value)}
                        ></Textarea>

                    </Flex>

                </DrawerBody>

                <DrawerFooter>
                    <Flex m={1} flex={1} width='95%' alignItems='flex-end'>
                        <Button m={2} colorScheme='twitter' onClick={() => props.EditLieferCall('close')}>Abbrechen</Button>
                        <Flex m={2} height='40px' >
                            <Flex bgColor='#db6a00' alignItems='center' justifyContent='center' px={2} borderRadius='5px 0px 0px 5px' >
                                <Text style={{color: '#fff', fontSize: '16px', fontWeight: 600}}>Speichern: </Text>
                            </Flex>
                            <Button onClick={() => saveHinweis('once')} colorScheme='orange' borderRadius='0px 0px 0px 0px' isDisabled={!props.UserRights.tour_manage} ml='2px'>Einmalig</Button>
                            <Button onClick={() => saveHinweis('all')} colorScheme='orange' borderRadius='0px 5px 5px 0px' ml='2px'>Permanent</Button>
                        </Flex>
                    </Flex>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>

    )
}

