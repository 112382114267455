import React, { useState, useEffect, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Icon, Flex, useToast, Center, Input } from '@chakra-ui/react'
import Cookies from 'universal-cookie';
import { useTable, useSortBy } from 'react-table'
import { FiSettings } from 'react-icons/fi'

import WebsiteHeader from '../GlobalComponents/WebsiteHeader';
import TableHead from '../GlobalComponents/TableHead';
import EditUser from './EditUser';
import CreateUser from './CreateUser';
import CreateTeam from './CreateTeam';
import EditTeam from './EditTeam';
import CreateRole from './CreateRole';
import { ErrorToast, WarnToast, CheckToast } from '../GlobalComponents/CustomToast';

export default function UserPannel(props) {
    const [tableData, setTableData] = useState([]);
    const [tableSearchData, setTableSearchData] = useState([]);
    const [teamData, setTeamData] = useState([]);

    const [SubSection, setSubSection] = useState('allUsers');
    const [currentUser, setCurrentUser] = useState({});
    const [currentTeam, setCurrentTeam] = useState({});

    const toast = useToast();
    const searchRef = useRef(null);

    useEffect(() => {
        _fetchUsers();
    }, []);
    async function EditUserCall(comand, value1, value2 = false) {
        if (comand === 'back') {
            setSubSection('allUsers')
            setTableSearchData(tableData)
        }

        if (comand === 'request') {
            var data = await sendData(value1.target, value1.params, value1.body)
            if (value2) { _fetchUsers(); }//keep in sync 
            return data;

        }
    }
    async function sendData(target, params, json = {}) {

        var url = props.server + '/API/index.php/' + target + params;
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        json.Token = UserToken;
        //console.log(json)
        try {
            const response = await fetch(url, {
                method: 'POST',
                //headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Basic VHJhdmVsZXI6VGhpczEhSXMyIlRoZTPCp1Bhc3N3b3JkNCQ=' },
                body: JSON.stringify(json)
            });
            const results = await response.json();
            //console.log(results)
            if (results === 'invalid') {
                props.searchCall('logOut')
            } else if (results.AuthKey === 'noRight') {
                toast({ render: () => ( <WarnToast title='Berechtigungen Fehlerhaft' descr='Sie besitzen keine Berechtigung für diese Aktion!'/>), status: 'error', duration: 7000, isClosable: true, position: 'top', })
                props.searchCall('newRights', results.Rights)
            } else return results
        } catch (error) { console.log(error) }
    }

    async function _fetchUsers() {

        var data = await sendData('Web_LoadAllUsers', '', {})

        if (Array.isArray(data)) {
            setTableData(data)
            setTableSearchData(data)
            //console.log(data)
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Userdaten sind kein Array!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }
    async function getTeamData(Team) {

        var params = '?Team=' + Team
        var data = await sendData('GET_TeamData', params, {})

        if (typeof data == 'object') {
            setTeamData(data)
            //console.log(data)
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Teamdaten sind kein Objekt!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }

    const columns = [
        {
            label: 'Nutzer',
            accessor: 'User_Name',
            sortable: true,
            width: 150,
        },
        {
            label: 'Role',
            accessor: 'Role_Name',
            sortable: true,
            width: 150,
        },
        {
            label: 'Team',
            accessor: 'Teams',
            sortable: false,
            width: 150,
        },
    ]
    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...tableSearchData].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setTableSearchData(sorted);
        }
    };
    const SearchSort = (search) => {
        const result = tableData.filter((item) => item.User_Name.toLowerCase().includes(search.toLowerCase()));
        //const results = tableData.filter((item) => item.User_Role.toLowerCase().includes(search.toLowerCase()));
        //console.log(result);
        setTableSearchData(result)
    }
    function userEditCall(command) {
        if (command === 'loadDetails') {

        }
    }
    function prepareUserEdit(ID) {

        const UserObj = tableData.find(element => element.User_ID === ID);
        if (UserObj !== undefined) {

            setCurrentUser(UserObj)
            setSubSection('editUser')

        }
    }
    const TeamComponent = () => {
        return (
            <Flex w='100%' p={2} flexDirection='column' >

                <Text fontSize={23}>Beschreibung</Text>
                <Text fontSize={17} ml={5}>{teamData.Data[0].Team_Dscr}</Text>

                <Text fontSize={23}>Andere Mitglieder: </Text>
                {teamData.Users.map((user) => {
                    return <Text fontSize={17} ml={5}>{user.User_Name}</Text>
                })}
                <Button colorScheme='blue' m='2px' w={40} onClick={() => [setCurrentTeam(teamData.Data[0]), setSubSection('editTeam')]}
                    isDisabled={!props.UserRights.device_manage}//() => getTeamData(element.ID)}
                >Team Bearbeiten</Button>

            </Flex>
        )
    }

    if (SubSection === 'editUser') {
        return (
            <EditUser EditUserCall={EditUserCall} currentUser={currentUser} ActiveUser={props.ActiveUser} server={props.server}
            />
        )
    } else if (SubSection === 'createUser') {
        return (
            <CreateUser EditUserCall={EditUserCall} ActiveUser={props.ActiveUser} server={props.server}
            />
        )
    } else {
        return (
            <Flex flexDirection='column'>
                <WebsiteHeader Text='TourPortal / Nutzer verwalten' onClick={() => props.searchCall('back')} server={props.server} User={props.ActiveUser}
                />
                <Flex h='93vh'>
                    <Flex flexDirection='column' w='75%' minHeight='406px' height='100%'>
                        <Flex borderWidth={1} borderRadius={10} m={1} borderColor='#000' bgColor='#eee' p={5}>

                            <Button colorScheme='teal' mx='5px' w='150px' onClick={() => setSubSection('createUser')} isDisabled={!props.UserRights.device_manage}>Benutzer erstellen</Button>
                            <Button colorScheme='teal' mx='5px' w='150px' onClick={() => setSubSection('createTeam')} isDisabled={!props.UserRights.device_manage}>Team erstellen</Button>
                            <Button colorScheme='teal' mx='5px' w='150px' onClick={() => setSubSection('createRole')} isDisabled={!props.UserRights.device_manage}>Role erstellen</Button>

                        </Flex>

                        <Flex borderWidth={1} borderRadius={10} m={1} borderColor='#000' bgColor='#eee' flexDirection='column' justifyContent='flex-start' p={5} h='90%' >

                            <Flex style={{ height: 60, width: '100%', marginBottom: '15px' }} >

                                <Input w='20%' ref={searchRef}
                                    style={{ backgroundColor: 'white', borderColor: '#222', fontSize: '20px' }}
                                    onKeyPress={e => { if (e.key === 'Enter') { SearchSort(searchRef.current.value) } }}
                                />
                                <Button colorScheme='teal' mx='5px' w='150px' onClick={() => SearchSort(searchRef.current.value)} isDisabled={!props.UserRights.device_manage}>Nutzer Suchen</Button>

                            </Flex>

                            <Flex style={{ width: '100%', marginBottom: '15px' }} overflow='auto'>
                                <Table size="small">
                                    <TableHead {...{ columns, handleSorting }} />
                                    <Tbody>
                                        {tableSearchData.map((row) => {
                                            return (
                                                <Tr key={row.User_Name}>
                                                    {columns.map(({ accessor }) => {
                                                        const tData = row[accessor];
                                                        return (<React.Fragment key={accessor}>
                                                            <Td borderWidth={1} bgColor={'#fff'} h='50px'
                                                                justifyContent={'flex-start'}
                                                            >
                                                                <Flex justifyContent="flex-start" alignItems="center" m={1}>

                                                                    {accessor === 'User_Name' ? (
                                                                        <Button colorScheme='teal' mx='5px' w='20px' onClick={() => prepareUserEdit(row.User_ID)} isDisabled={!props.UserRights.device_manage}>
                                                                            <Icon as={FiSettings} color='#fff' boxSize={18} />
                                                                        </Button>

                                                                    ) : (null)}

                                                                    {accessor === 'Teams' ? (
                                                                        tData.map((element) => {
                                                                            if (element.Team_ID !== null) return (
                                                                                <Button key={element.Team_ID} colorScheme='teal' m='2px' onClick={() => getTeamData(element.Team_ID)}
                                                                                >
                                                                                    {element.Team_Name}
                                                                                </Button>
                                                                            )
                                                                        })
                                                                    ) : (<Text noOfLines={1} fontSize={19} >{tData}</Text>)}
                                                                </Flex>
                                                            </Td>
                                                            <Td className="columnResizer" />
                                                        </React.Fragment>);
                                                    })}
                                                </Tr>
                                            );
                                        })}
                                    </Tbody>
                                </Table>
                            </Flex>

                        </Flex>
                    </Flex>

                    <Flex borderWidth={1} borderRadius={10} m={1} borderColor='#000' bgColor='#eee' alignItems='flex-start' w='25%' flexWrap='wrap' justifyContent='flex-start' overflow='auto' p={5} minHeight='400px' minWidth='250px'>

                        <Flex bgColor='#fff' h='100%' w='100%' flexDirection='column'>

                            <Flex borderColor='#000' borderWidth={1} w='100%' h='7%' p={2} justifyContent='center' alignItems='center'>
                                <Text fontSize={23}>{teamData.length !== 0 ? (teamData.Data[0].Team_Name) : ('Kein Team ausgewählt')}</Text>
                            </Flex>

                            {teamData.length !== 0 ? (TeamComponent()) : (null)}

                        </Flex>
                    </Flex>
                </Flex >
                <CreateTeam isOpen={SubSection === 'createTeam' ? (true) : (false)} EditUserCall={EditUserCall} server={props.server} />
                <EditTeam isOpen={SubSection === 'editTeam' ? (true) : (false)} EditUserCall={EditUserCall} currentTeam={currentTeam} server={props.server} />
                <CreateRole isOpen={SubSection === 'createRole' ? (true) : (false)} EditUserCall={EditUserCall} currentTeam={currentTeam} server={props.server} />
            </Flex >
        )
    }

}