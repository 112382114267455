import React, { useState, useEffect, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Icon, Flex, useToast, Center, Input, Select, InputGroup, InputRightElement, VStack, Textarea, Alert } from '@chakra-ui/react'

import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerCloseButton, DrawerContent, } from '@chakra-ui/react'

import ChacraAlert from '../GlobalComponents/ChakraAlert';
import { ErrorToast, WarnToast, CheckToast } from '../GlobalComponents/CustomToast';

export default function CreateDevToken(props) {

    const [tableData, setTableData] = useState([]);
    const [NameText, setNameText] = useState('');
    const [change, setChange] = useState(false);

    const DeviceRef = useRef(null);

    const toast = useToast();
    const toastIdRef = React.useRef();

    useEffect(() => {
    }, []);

    function collectSavedData() {
        var DeviceID = DeviceRef.current.value

        if (DeviceID.length === 0) {
            toast({ render: () => ( <WarnToast title='Angaben unvollständig!' descr="Bitte geben Sie eine DeviceID an!"/>), 
            duration: 6000, isClosable: true, position: 'top', })
            return;
        }
        props.AddDrawerCall('request', DeviceID);
    }


    return (
        <>
            <Drawer
                isOpen={props.isOpen}
                size='sm'
                closeOnEsc
                placement='right'

            >
                <DrawerOverlay />
                <DrawerContent borderWidth={1} borderRadius={10} borderColor='#000' bgColor='#eee' m={1}>
                    <DrawerCloseButton onClick={() => props.AddDrawerCall('back')} />
                    <DrawerHeader fontSize={35} >Dev-Token Erstellen</DrawerHeader>

                    <DrawerBody p={4}>

                        <Flex bgColor='#fff' p={2} h='97%' flexDirection='column'>
                            <Flex flexDirection='column' overflow='hidden'>
                                <Text fontSize='27' fontWeight='bold' mt='10%'>Device ID:</Text>
                                <Input w='100%' ref={DeviceRef} bgColor='#fff' type='text' />
                                <Text fontSize='20' mt='10%'>Bitte die Device ID des Gerätes angeben, welches per Dev-Token registriert werden soll</Text>
                            </Flex>

                        </Flex>

                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' colorScheme='blue' mr={3} onClick={() => props.AddDrawerCall('back')} >Abbrechen</Button>
                        <Button colorScheme='blue' onClick={() => collectSavedData()}>Erstellen</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}