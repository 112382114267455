import React, { useState, useEffect, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Flex, useToast, Input, InputGroup, InputRightElement, Select, Icon, IconButton } from '@chakra-ui/react'
import DatePicker from 'react-date-picker';
import '../css/DatePicker.css';
import '../css/Calendar.css';
import '../css/App.css'
import { RiCloseFill } from "react-icons/ri";
import { FaRegCalendarTimes } from 'react-icons/fa';

import TableHead from '../GlobalComponents/TableHead';
import WebsiteHeader from '../GlobalComponents/WebsiteHeader';
import { ErrorToast, WarnToast, InfoToast, CheckToast } from '../GlobalComponents/CustomToast';
import { ManifestFilterComp } from '../GlobalComponents/ManifestFilterComp';


export default function ManifestSucheTable (props){

  const SortRef = React.useRef();
  
  useEffect(() => {

    InitSearchTable();

  },[]);

  const InitSearchTable = async () => {

    var result = await props.searchTableCall('init');
    console.log(result); //debug
    if (result !== false) {
      sortingReciever('makeFilter', result);
    } else {
      console.log(props.PersFilterValue);
      sortingReciever('setFilter', props.PersFilterValue);
    }
  }
  const SearchManifHandle = async () => {

    var result = await props.searchTableCall('seak_m');
    console.log(result); //debug
    sortingReciever('makeFilter', result);

  }

  const columns = [
    { label: 'Tour', accessor: 'Tour', sortable: true, width: 100 },
    { label: 'Fahrer', accessor: 'Fahrer_Name', sortable: true, width: 100},
    { label: 'Manifestnummer', accessor: 'Manifestnummer', sortable: true, width: 100 },
    { label: 'Abladestellen', accessor: 'Ablade_Anzahl', sortable: true, width: 40 },
    { label: 'Lieferstellen', accessor: 'Liefer_Anzahl', sortable: true, width: 40 },
    { label: 'Pakete', accessor: 'Pakete_Anzahl', sortable: true, width: 40 },
    { label: 'Papier', accessor: 'Papier_Anzahl', sortable: true, width: 40 },
    { label: 'Datum', accessor: 'Datum', sortable: true, width: 40 },
  ]

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
        const sorted = [...props.FilteredManifestData ].sort((a, b) => {
            if (a[sortField] === null) return 1;
            if (b[sortField] === null) return -1;
            if (a[sortField] === null && b[sortField] === null) return 0;
            return (
                a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                    numeric: true,
                }) * (sortOrder === "asc" ? 1 : -1)
            );
        });
        props.searchTableCall('sort_t', sorted)
    }
  };  

  const sortingReciever = (comand, value1, value2) => {
    if (SortRef.current) {
    SortRef.current.Reciever(comand, value1, value2);
    }
  };

  const EditButtonHandler = () => {

    //var manifIndex = props.FilteredManifestData.findIndex((item) => item.Manifestnummer == props.selected)
    if (props.UserRights.tour_manage_all || props.UserRights.tour_manage) {

        return <Button m={2} colorScheme='twitter' 
        //onClick={() => [_fetchData(curentManifest.Manifestnummer, 'sort'), transformRoute(curentManifest.Manifestnummer)]}
        onClick={() => {
            props.searchTableCall('open_e');
        }}
        >Manifest Bearbeiten</Button>
    } else {
        return <Button m={2} colorScheme='twitter' isDisabled={true}>Manifest Bearbeiten</Button>
    }
  }
  const colorMaker = (Status) => {
    if (Status === 'complete') {
        return '#b8edc6'
    } else if (Status === null) {
        return 'azure'
    } else if (Status === 'live') {
        return '#fdd386'
    } else {
        return '#fcb1b1'
    }
  }

  return (
      <Flex flexDirection='column'>
          <WebsiteHeader Text='TourPortal / Manifest Suche' onClick={() => props.searchTableCall('back')} server={props.server} User={props.ActiveUser} />

          <Flex height='93vh' backgroundColor='#ccc' p={1} flexDirection='column'>

          <Box display='flex' bg='#fff' alignItems='center' justifyContent='flex-start' flexWrap='wrap'
          mb={1} borderWidth={1} borderColor='#000' borderRadius={3} >

              <Flex m={2} width='530px' height='40px' border='0px solid black' alignItems='center' justifyContent='space-between' borderRadius={6} bg='#1DA1F2'>
              <IconButton bg='#1DA1F2' color='#fff' width='40px' 
              icon={<Icon as={FaRegCalendarTimes} color='#fff' boxSize={25}/>} onClick={() => props.searchTableCall('setdays')} 
              colorScheme='twitter' borderRadius='5px 0px 0px 5px'/>
                  <Text  color='#fff' fontWeight='semibold' fontSize={17} >von:</Text>
                  <DatePicker onChange={(value) => props.searchTableCall('date_1', value)} value={props.Date1} format='dd.MM.y' border='0px solid black'/>
                  <Text  color='#fff' fontWeight='semibold' fontSize={17} >bis:</Text>
                  <DatePicker onChange={(value) => props.searchTableCall('date_2', value)} value={props.Date2} format='dd.MM.y' border='0px solid black'/>
                  <Button onClick={() => SearchManifHandle()} colorScheme='twitter' borderRadius='0px 5px 5px 0px'>Suche</Button>
              </Flex>

              <ManifestFilterComp ref={SortRef} FilterCall={props.FilterCall} ManifestArrayOrigin={props.ManifestData} PersFilterData={props.PersFilterData} 
              HideTeamFilter={ !props.UserRights.tour_view_all} HideDriverFilter={ !props.UserRights.tour_view_all}/>

          </Box>
          {props.selected !== '' ? (
              <Box display='flex' h='65px' bg='#fff' alignItems='center' justifyContent='flex-start' mb={1} borderWidth={1} borderColor='#000' borderRadius={3} >
                  <>
                      <Button m={2} colorScheme='twitter' isDisabled={!props.UserRights.tour_view} onClick={() => {
                          //setSubSection('view')

                          props.searchTableCall('open_v')
                          
                          //_fetchData(curentManifest.Manifestnummer, 'view'), 
                          //transformRoute(curentManifest.Manifestnummer)
                      }}>Manifest Anschauen</Button>
                      {EditButtonHandler()}
                  </>
              </Box>
          ) : (null)}
          
          <Box display='flex' height='100%' bg='#fff' alignItems='flex-start' justifyContent='center'
              borderWidth={1} borderColor='#000' borderRadius={3} padding={2} overflow='auto'>
              <Table size="small">
                  <TableHead {...{ columns, handleSorting }} />
                  <Tbody>
                      {props.FilteredManifestData.map((row, index) => {
                          return (

                              <Tr key={row.Manifestnummer} onClick={() => props.searchTableCall('select', index )}
                                  bg={() => colorMaker(row.Status)}
                                  border={props.selected === row.Manifestnummer ? '3px dashed #0060d5' : null}
                              >
                                  {columns.map(({ accessor }) => {
                                      const tData = row[accessor];
                                      return (<React.Fragment key={accessor}>
                                          <Td textAlign="center" borderWidth={1} >
                                              <Text noOfLines={1}>{tData}</Text>
                                          </Td>
                                          <Td className="columnResizer" />
                                      </React.Fragment>
                                      );
                                  })}
                              </Tr>
                          );
                      })}
                  </Tbody>
              </Table>
          </Box>
          </Flex>
      </Flex >
  );
}