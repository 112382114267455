import React, { useState, useEffect, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Flex, useToast, Input, InputGroup, InputRightElement, Select, Icon, IconButton } from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'
import Cookies from 'universal-cookie';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation, useSearchParams  } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import '../css/DatePicker.css';
import '../css/Calendar.css';
import '../css/App.css'
import { RiCloseFill } from "react-icons/ri";
import { FaRegCalendarTimes } from 'react-icons/fa';

import TableHead from '../GlobalComponents/TableHead';
import WebsiteHeader from '../GlobalComponents/WebsiteHeader';
import ManifestSucheTable from './ManifestSucheTable';
import ViewManifest from './ManifestView';
import SortManifest from './TourEdit/ManifestEdit';
import { ErrorToast, WarnToast, InfoToast, CheckToast } from '../GlobalComponents/CustomToast';
import { ManifestFilterComp } from '../GlobalComponents/ManifestFilterComp';

export default function SearchManifest(props) {
    const HomeData = { startStr: "Chemnitzer Str 3", startPLZ: "09573", startOrt: "Augustusburg", startLat: 50.81992892, startLong: 13.08140153, endStr: "Chemnitzer Str 3", endPLZ: "09573", endOrt: "Augustusburg", endLat: 50.82032892, endLong: 13.08180153 };

    const [selected, setSelected] = useState('');
    const [ManifesteData, setManifesteData] = useState([]); //full underlying Manifest Collection
    const [Date1, setDate1] = useState(null);
    const [Date2, setDate2] = useState(null);
    const [SubSection, setSubSection] = useState('select');

    const [curentManifest, setCurentManifest] = useState({});
    const [TourData, setTourData] = useState([]);
    const [TourUnset, setTourUnset] = useState([]);
    const [RouteObj, setRouteObj] = useState([{ realRouteData: [], realRoutePoly: [], computeRouteData: [], computeRoutePoly: [] }])
    const [ExtraData, setExtraData] = useState(HomeData);
    const [DriverData, setDriverData] = useState([]);

    const [tableData, setTableData] = useState([]); //filtered Manifest Collection
    const [PersFilterValue, setPersFilterValue] = useState({Tour: '', Team: '', Driver: '', Manifest: ''});
    const [PersFilterData, setPersFilterData] = useState({Tours: [], Teams: [], Drivers: [] });

    const toast = useToast();
    const toastIdRef = React.useRef();

    //depracated here
    const SortRef = React.useRef();

    const firstRender = useRef(true);

    const navigate = useNavigate(); //navigate to a certain path
    const [searchParams, setSearchParams] = useSearchParams(); //read and set query-params

    useEffect(() => {

        //console.log('ManifestSucheHandler');

    },[])
    //depracated here
    const columns = [
        {
            label: 'Tour',
            accessor: 'Tour',
            sortable: true,
            width: 100,
        },
        {
            label: 'Fahrer',
            accessor: 'Fahrer_Name',
            sortable: true,
            width: 100,
        },
        {
            label: 'Manifestnummer',
            accessor: 'Manifestnummer',
            sortable: true,
            width: 100,
        },
        {
            label: 'Abladestellen',
            accessor: 'Ablade_Anzahl',
            sortable: true,
            width: 40,
        },
        {
            label: 'Lieferstellen',
            accessor: 'Liefer_Anzahl',
            sortable: true,
            width: 40,
        },
        {
            label: 'Pakete',
            accessor: 'Pakete_Anzahl',
            sortable: true,
            width: 40,
        },
        {
            label: 'Papier',
            accessor: 'Papier_Anzahl',
            sortable: true,
            width: 40,
        },
        {
            label: 'Datum',
            accessor: 'Datum',
            sortable: true,
            width: 40,
        },
    ]
    const sortingReciever = (comand, value1, value2) => {
        if (SortRef.current) {
        SortRef.current.Reciever(comand, value1, value2);
        }
    };
    //depracated here
    function reloadFilters() {
        
        if (SubSection === 'select') {
            sortingReciever('setFilter', PersFilterValue);
        }
    }
    async function PersValueSetter(key, value) {
        var oldData = PersFilterValue;
        oldData[key] = value;
        setPersFilterValue(PersFilterValue);
    }
    //depracated here
    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...tableData].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setTableData(sorted);
        }
    };   
    const _initMainPage = async () => { //procedure to setup the default main page

        if (Date1 !== null && Date2 !== null ){

            return false;

        } else {

            const Date = await _getsetPrevDay();

            return await _fetchManifeste(Date[0], Date[1]);
        
        }
    }
    async function _getsetPrevDay(){

        var date = new Date()
        const day = date.getDay(); 
        const daysToSubtract = day === 0 ? 2 : day === 1 ? 3 : 1; 
        const previousWorkday = new Date(date); 
        previousWorkday.setDate(date.getDate() - daysToSubtract); 
        setDate1(previousWorkday);
        setDate2(date);
        return [previousWorkday, date];
    }
    async function sendData(target, params, json = {}) {

        var url = props.server + '/API/index.php/' + target + params;
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        json.Token = UserToken;
        console.log(url, json)
        try {
            const response = await fetch(url, {
                method: 'POST',
                //headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Basic VHJhdmVsZXI6VGhpczEhSXMyIlRoZTPCp1Bhc3N3b3JkNCQ=' },
                body: JSON.stringify(json)
            });
            const results = await response.json();
            console.log(results)
            if (results === 'invalid') {
                props.searchCall('logOut')
            } else if (results.AuthKey === 'noRight') {
                toast({ render: () => ( <WarnToast title='Berechtigungen Fehlerhaft' descr='Sie besitzen keine Berechtigung für diese Aktion!'/>), status: 'error', duration: 7000, isClosable: true, position: 'top', })
                props.searchCall('newRights', results.Rights)
            } else return results
        } catch (error) { console.log(error) }
    }

    async function createTravelTime(Tour) {

        var CurrentTime = Math.round( Date.now() / 1000);

        var today = new Date().toJSON().slice(0, 10);

        for (let i = 0; i < Tour.length; i++) {
           
            if ( Number.isInteger(Tour[i].TravelTime)) {

                   if (Tour[i].Arrived == null) { // has AbladeSt started?

                        var newTime = CurrentTime + Tour[i].TravelTime;
                        //console.log('set Time Expected Arrived: ' + new Date(newTime * 1000).toLocaleString())
                        Tour[i].TravelTime = new Date(newTime * 1000).toLocaleString().slice(-8);
                        CurrentTime = newTime + 300;

                   } else if (Tour[i].Departed == null) { //has abladeSt ended?
                
                        var newTime = Math.floor(new Date(today + ' ' + Tour[i].Arrived).getTime() / 1000);
                        newTime += 300;
                        //console.log('set Time Expected Departed: ' + new Date(newTime * 1000).toLocaleString())
                        CurrentTime = newTime;

                   } else {
                        var newTime = Math.floor(new Date(today + ' ' + Tour[i].Departed).getTime() / 1000);
                        //console.log('Time Departed: ' + Tour[i].Departed )
                        CurrentTime = newTime;
                   }
            }
        }
        return Tour;
    }
    async function _fetchManifeste( StartDate = Date1, EndDate = Date2 ) {

        var start = StartDate.getFullYear() + '-' + (StartDate.getMonth() + 1) + '-' + StartDate.getDate()
        var end = EndDate.getFullYear() + '-' + (EndDate.getMonth() + 1) + '-' + EndDate.getDate()
        //var params = '?start=' + start + '&end=' + end

        var data = await sendData('Web_LoadManifesteOther', '', {
                "start": start,
                "end": end,
        }) 
        // var data = await sendData('GET_TeamManifest', '', {})

        if ( typeof data === 'object' && !Array.isArray(data) && data !== null) {
            
            setManifesteData(data.Manifeste);
            setCurentManifest({});
            setTableData(data.Manifeste);

            delete data.Manifeste; 

            setPersFilterData( data );
            return data;
            //Make FilterValues and reset Selection
            //funktion unused sortingReciever('makeFilter', data);
            
        } else if (data !== 'invalid') toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Manifestdaten sind kein Array!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }
    async function _fetchData(ManifestNr, screen, alert = true) {

        var params = '?Manifest=' + ManifestNr + '&Action=' + screen 
        var data = await sendData('Web_LoadTour', params, {})

        //console.log(typeof data, data.length)
        if (typeof data === 'object' && data.Tour.length !== 0) {

            setTourData([]);
            setTourUnset([])
            setDriverData([]);
            
            var TimedTour = await createTravelTime(data.Tour) 

            setTourData(TimedTour);
            if (data.UnsetTour) setTourUnset(data.UnsetTour);
            if (data.ExtraData) setExtraData(data.ExtraData);
            if (data.DriverData) setDriverData(data.DriverData);
            if (data.ManifestData) setCurentManifest(data.ManifestData);
            transformRoute({RealRoute: data.RealRoute, ComputeRoute: data.ComputeRoute});
            return true;
        } else if (data === 'disallowed'){
            toast({ render: () => ( 
                <ErrorToast title='Nicht erlaubt!' descr="Tour ist diesem Fahrer nicht zugewiesen!"/>
            ), status: 'warning', duration: 7000, isClosable: true, position: 'top', })
            navigate(props.route);

        } else if (alert){
            toast({ render: () => ( 
                <ErrorToast title='Abruf fehlgeschlagen!' descr="Diese Tour ist leer!"/>
            ), status: 'error', duration: 7000, isClosable: true, position: 'top', })
        }
        //console.log(data)
    }
    async function _fetchRoute(ManifestNr) {

        var params = '?Manifest=' + ManifestNr
        var data = await sendData('RealRoute', params, {})

        //console.log(typeof data, data.length)
        if (typeof data === 'object' ) {

            transformRoute(data)
            
            return true
        } else {
            toast({ render: () => ( 
                <ErrorToast title='Abruf fehlgeschlagen!' descr="Routendaten konnten nicht geladen werden!"/>
            ), status: 'error', duration: 7000, isClosable: true, position: 'top', })
        }
        //console.log(data)
    }
    async function transformRoute(data) { //builds compute- and realRoute
        //console.log(data)

        var RouteAll = {};

        if (data.RealRoute && Array.isArray(data.RealRoute)) {

            const RRoute = [];
            data.RealRoute.forEach((item) => {
                RRoute.push([item.Latitude, item.Longitude])
            })
            RouteAll.realRoutePoly = RRoute;
            RouteAll.realRouteData = data.RealRoute;
            }
        if (data.ComputeRoute && Array.isArray(data.ComputeRoute)) {
            const CRoute = [];
            data.ComputeRoute.forEach((item) => {
                CRoute.push([item.Latitude, item.Longitude])
            })
            RouteAll.computeRoutePoly = CRoute;
            RouteAll.computeRouteData = data.ComputeRoute;
        }

        setRouteObj(RouteAll)
        return true;
    }
    async function _fetchAfterMovingData(ManifestNr, screen, user = null) {

        var params = '?Manifest=' + ManifestNr + '&Action=' + screen + '&User=' + user
        var data = await sendData('Web_LoadTour', params, {})

        if (typeof data === 'object' ) {

            setTourData([]);
            setDriverData([]);

            if (data.Tour.length !== 0) {

            setTourData(data.Tour);
            if (data.UnsetTour) setTourUnset(data.UnsetTour);
            if (data.ExtraData) setExtraData(data.ExtraData);
            if (data.DriverData) setDriverData(data.DriverData);
            setSubSection(screen)
            } else {
                setSubSection('select');
            }
        } 
    }
    async function load_Coords(ManifestNr) { //depraceted due to Model_Abladest
        /*
        var params = '?Manifest=' + ManifestNr
        var data = await sendData('Web_GenerateCoords', params, {})

        if (Array.isArray(data) && data.length === 0) {
            toast({ render: () => ( <InfoToast title='Coordinaten geladen!' descr="Es wurden keine neuen Coordinaten erzeugt"/>), status: 'info', duration: 7000, isClosable: true, position: 'top-', })

        } else if (Array.isArray(data) && data.length !== 0) {
            toast({ render: () => ( <CheckToast title='Coordinaten geladen!' descr="Neue Coordinaten wurden generiert"/>), status: 'info', duration: 7000, isClosable: true, position: 'top', })
            _fetchData(ManifestNr, 'sort')

        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Coordinaten konnten nicht generiert werden!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
        */
    }
    async function sendOrder(activeAblade, unsetAblade) {
        toastIdRef.current = toast({ render: () => ( <InfoToast title='Reihenfolge speichern!' descr="Daten werden gesendet..."/>), status: 'info', duration: 7000, isClosable: true, position: 'top-right', })

        const OrderData = [];
        activeAblade.forEach((item, index) => {
            var object = { Manifest: item.Manifest, AbladeID: item.AbladeID, Ordn: index + 1 }
            OrderData.push(object);
        })
        const UnsetData = [];
        unsetAblade.forEach((item, index) => {
            var object = { Manifest: item.Manifest, AbladeID: item.AbladeID}
            UnsetData.push(object);
        })

        console.log(OrderData, UnsetData)

        var data = await sendData('Web_SaveAbladeOrder', '', { OrderData: OrderData , UnsetData: UnsetData })

        if (data === 'success') {

            _fetchRoute(curentManifest.Manifestnummer);

            toast.update(toastIdRef.current, { render: () => ( <CheckToast title='Reihenfolge speichern!' descr='Daten wurden gespeichert'/>) })
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Ablade Reihenfolge konnte nicht gespeichert werden"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }
    async function sendAbladeHinweis(item, param, type) {
        toastIdRef.current = toast({ render: () => ( <InfoToast title='Abladehinweis speichern!' descr="Daten werden gesendet..."/>), status: 'info', duration: 7000, isClosable: true, position: 'top', })
        
        if (type === 'all'){
            var object = { Manifest: item.Manifest, AbladeSt: item.AbladeID, Kennzeichen: item.Kennzeichen, Ort: item.Ort, PLZ: item.PLZ, Hinweis: param, Type: type }
            var data = await sendData('Web_UpdateAbladeHinweis', '', object)
        } else {
            var object = { Manifest: item.Manifest, AbladeSt: item.AbladeID, Hinweis: param, Type: type }
            var data = await sendData('Web_UpdateAbladeHinweis', '', object)
        }

        if (data === 'success') {
            toast.update(toastIdRef.current, { render: () => ( <CheckToast title='Abladehinweis speichern!' descr='Daten wurden gespeichert'/> )})
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Abladehinweis konnte nicht gespeichert werden!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
        return data;
    }
    async function sendLieferHinweis(item, param, type) {
        toastIdRef.current = toast({ render: () => ( <InfoToast title='Abladehinweis speichern!' descr="Daten werden gesendet..."/>), status: 'info', duration: 7000, isClosable: true, position: 'top', })
        
        if (type === 'all'){
            var object = { Manifest: item.Manifest, LieferSt: item.LieferID, LieferHash: item.LieferHash, Hinweis: param, Type: type }
            var data = await sendData('Web_UpdateLieferHinweis', '', object)
        } else {
            var object = { Manifest: item.Manifest, LieferSt: item.LieferID, Hinweis: param, Type: type }
            var data = await sendData('Web_UpdateLieferHinweis', '', object)
        }

        if (data === 'success') {
            toast.update(toastIdRef.current, { render: () => ( <CheckToast title='Lieferhinweis speichern!' descr='Daten wurden gespeichert'/> )})
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Lieferhinweis konnte nicht gespeichert werden!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
        return data;
    }
    async function sendOrderLS(AbladeIndex, Lieferstellen) {
        toastIdRef.current = toast({ render: () => ( <InfoToast title='Lieferstellen speichern!' descr="Daten werden gesendet..."/>), status: 'info', duration: 7000, isClosable: true, position: 'top', })
        const OrderData = [];
        Lieferstellen.forEach((item, index) => {
            var object = { Manifest: item.Manifest, AbladeSt: item.AbladeID, LieferID: item.LieferID, Reihe: index + 1 }
            OrderData.push(object);
        })

        var data = await sendData('Web_SaveLieferOrder', '', { OrderData: OrderData })

        if (data === 'success') {
            toast.update(toastIdRef.current, { render: () => ( <CheckToast title='Lieferstellen speichern!' descr='Daten wurden gespeichert'/> )})
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Liefer Reihenfolge konnte nicht gespeichert werden!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
        return data;
    }
    async function sendCoords(Manifest, Ablade, KennZ, Lat, Long) {

        var json = {
            Manifest: Manifest,
            AbladeID: Ablade,
            Kennzeichen: KennZ,
            Lat: Lat,
            Long: Long
        }
        //console.log(json)
        var data = await sendData('Web_UpdateAbladeCoords', '', json)

        if (data === 'success') {
            toast({ render: () => ( <CheckToast title='Coordinaten senden!' descr="Coordinaten wurden gespeichert"/>), status: 'info', duration: 7000, isClosable: true, position: 'top', })
        } else {
            toast({ render: () => ( <ErrorToast title='Coordinaten senden!' descr="Coordinaten konnten nicht gespeichert werden"/>), status: 'error', duration: 7000, isClosable: true, position: 'top', })
            console.log('invalid Data: ' + data)
        }
    }
    async function sendLCoords(LieferHash, Lat, Long) {

        var json = {
            LieferHash: LieferHash,
            Lat: Lat,
            Long: Long
        }
        //console.log(json)
        var data = await sendData('Web_UpdateLieferCoords', '', json)

        if (data === 'success') {
            toast({ render: () => ( <CheckToast title='Coordinaten senden!' descr="Coordinaten wurden gespeichert"/>), status: 'info', duration: 7000, isClosable: true, position: 'top', })
        } else {
            toast({ render: () => ( <ErrorToast title='Coordinaten senden!' descr="Coordinaten konnten nicht gespeichert werden"/>), status: 'error', duration: 7000, isClosable: true, position: 'top', })
            console.log('invalid Data: ' + data)
        }
    }
    async function createLieferPDF(LieferID, Manifest) {
        
        var target = 'Web_LieferPDF'
        var params = '?LieferST=' + LieferID + '&Manifest=' + Manifest
        var url = props.server + '/API/index.php/' + target + params;
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        var json = {};
        json.Token = UserToken;
        try {
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(json)
            })
                .then(response => response.blob())
                .then(blob => {

                    const Purl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = Purl;
                    var path = Manifest + '_' + LieferID + '.pdf';
                    link.download = path;
                    link.target = '_blank';
                    //document.body.appendChild(link);
                    link.click();
                    //document.body.removeChild(link);
                    //window.open(path, '_blank');
                    
                });
        } catch (error) { console.log(error) }
        
    }
    async function createBeladePDF(Manifest) {

        var target = 'Web_BeladePDF'
        var params = '?Manifest=' + Manifest
        var url = props.server + '/API/index.php/' + target + params;
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        var json = {};
        json.Token = UserToken;
        try {
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(json)
            })
                .then(response => response.blob())
                .then(blob => {
                    const Purl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = Purl;
                    link.download = Manifest + '_Beladeliste.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        } catch (error) { console.log(error) }
    }
    async function createPaketPDF(Manifest) {

        var target = 'Web_PaketPDF'
        var params = '?Manifest=' + Manifest
        var url = props.server + '/API/index.php/' + target + params;
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        var json = {};
        json.Token = UserToken;
        try {
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(json)
            })
                .then(response => response.blob())
                .then(blob => {
                    const Purl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = Purl;
                    link.download = Manifest + '_Paketliste.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        } catch (error) { console.log(error) }
    }
    function clearCurrentManifest() {

        setCurentManifest({});
        setTourData([]);
        setTourUnset([]);
        setDriverData([]);
        setRouteObj([]);
    }

    async function FilterCall(comand, value1, value2, value3) {

        console.log('FilterCall comand: ', comand)

        //console.log( value1)

        switch (comand) {
            case 'filtered':
                setTableData( value1 );
            break;
            case 'setPersData':
                setPersFilterData( value1 );
            break;
            case 'newSelected':
                PersValueSetter( value1, value2 );
            break;
            default:
                return;
          }
    }
    async function searchTableCall(comand, value1, value2, value3) {
        switch (comand) {

            case 'init': 
                return _initMainPage();

            case 'seak_m':
                return _fetchManifeste();

            case 'back':
                props.searchCall('back')
                break;

            case 'select': 
                handleSelect(value1);
                break;

            case 'open_v':
                searchParams.set('manifest',  selected);
                navigate(props.route + `/view?${searchParams.toString()}`);
                break;
            
            case 'open_e':
                searchParams.set('manifest',  selected);
                navigate(props.route + `/edit?${searchParams.toString()}`);
                break;
            case 'sort_t':
                setTableData(value1);
                break;
            case 'setdays':
                _getsetPrevDay()
            break;

            case 'date_1':
                setDate1(value1)
            break;

            case 'date_2':
                setDate2(value1)
            break;

            default: 
            break;
        }
    }
    async function viewCall(comand, value1, value2) {
        if (comand === 'back') {
            clearCurrentManifest();
            navigate(props.route);
        }
        if (comand === 'switch_v'){ navigate(props.route + `/view?${searchParams.toString()}`) }
        if (comand === 'switch_e'){ navigate(props.route + `/edit?${searchParams.toString()}`) }
        if (comand === 'createPDF') { createLieferPDF(value1, value2) }
        if (comand === 'createBeladePDF') { createBeladePDF(value1) }
        if (comand === 'createPaketPDF') { createPaketPDF(value1) }
        if (comand === 'setMap') { props.searchCall('setMap', value1) }
        if (comand === 'setFirst') { props.searchCall('setFirst', value1) }
        if (comand === 'reload') {
            var manif = searchParams.get('manifest');

            if( /^-?\d+(\.\d+)?$/.test(manif) && manif.length === 6){
                
                var dataResult = await _fetchData(manif, 'view');
                
            } else {
                navigate(props.route);
            }

            //var dataResult = await _fetchData(curentManifest.Manifestnummer, 'sort');
            //var routeResult = await transformRoute(curentManifest.Manifestnummer);
            /*
            if (dataResult && routeResult) {
                toast({ render: () => (<CheckToast title='Tour geladen!' descr='Die Tourdaten wurden neu geladen'/>), status: 'success', duration: 5000, isClosable: true, position: 'top', })
            }
            */ 
        }
    }
    async function _sortierCall(comand, oldIndex, newIndex, value3) {
        if (comand === 'createBeladePDF') { createBeladePDF(oldIndex) }
        if (comand === 'createPaketPDF') { createPaketPDF(oldIndex) }
        if (comand === 'setMap') { props.searchCall('setMap', oldIndex) }
        if (comand === 'setFirst') { props.searchCall('setFirst', oldIndex) }
        if (comand === 'reload') {
            var manif = searchParams.get('manifest');

            if(!isNaN(parseFloat(manif)) && manif.length === 6){

                var dataResult = await _fetchData(manif, 'sort');
                
            } else {
                navigate(props.route);
            }
            //var dataResult = await _fetchData(curentManifest.Manifestnummer, 'sort');
            //var routeResult = await transformRoute(curentManifest.Manifestnummer);
            /*if (dataResult && routeResult) {
                toast({ render: () => (<CheckToast title='Tour geladen!' descr='Die Tourdaten wurden neu geladen'/>), status: 'success', duration: 5000, isClosable: true, position: 'top', })
            }*/
        }
        if (comand === 'save') {
            
            setTourData(oldIndex);
            setTourUnset(newIndex);

            sendOrder(oldIndex, newIndex);

        }
        if (comand === 'saveHW') {
            var AbladeIndex = TourData.findIndex((item) => item.AbladeID === oldIndex)
            if (AbladeIndex !== -1) {

                var result = await sendAbladeHinweis(TourData[AbladeIndex], newIndex, value3);

                if (result === 'success') {
                    TourData[AbladeIndex].AbladeHinweis = newIndex;
                    setTourData(TourData); 
                }
                return result;
            }
        }
        if (comand === 'saveLHW') {

            var result = await sendLieferHinweis(oldIndex, newIndex, value3);
            if (result === 'success') {
                var AbladeIndex = TourData.findIndex((item) => item.AbladeID === oldIndex.AbladeID)
                var LieferIndex = TourData[AbladeIndex].Lieferstellen.findIndex((item) => item.LieferID === oldIndex.LieferID)

                if (AbladeIndex !== -1 && LieferIndex !== -1) {
                TourData[AbladeIndex].Lieferstellen[LieferIndex].LieferHinweis = newIndex;
                setTourData(TourData); 
                }
            }
            return result;
        }
        if (comand === 'saveLS') {
            var AbladeIndex = TourData.findIndex((item) => item.AbladeID === oldIndex)
            if (AbladeIndex !== -1) {
                var result = await sendOrderLS(AbladeIndex, newIndex);

                if (result === 'success') {

                    TourData[AbladeIndex].Lieferstellen = newIndex;
                    setTourData(TourData); 
                }
                return result;
            }
        }
        if (comand === 'getCords') {
            load_Coords(TourData[0].Manifest)
        }
        if (comand === 'getAvailableTours') {

            var body = {
                start: newIndex,
                end: oldIndex,
                manifest: curentManifest.Manifestnummer
            }
            var data = await sendData('Web_LoadManifesteOpen', "", body)
            return data;
        }
        if (comand === 'getTargetAblade') {

            var params = '?Manifest=' + oldIndex
            var data = await sendData('W_LoadTargetAblade', params, {})
            return data;
        }
        if (comand === 'MoveAbladestellen') {

            var params = '';
            var data = await sendData('A_MoveAbladestellen', params, oldIndex)
            return data;
        }
        if (comand === 'DisplayMovementResult') {

            //get TourData / set TourData
            await _fetchAfterMovingData(oldIndex, 'sort', null, false)

            console.log(TourData);
            //get TargetManifest
            var params = '?Manifest=' + newIndex
            var Manifest = await sendData('Web_FindManifest', params, {})

            //get TargetAblade
            var params = '?Manifest=' + newIndex
            var Ablade = await sendData('W_LoadTargetAblade', params, {})

            //return Target Manifest+Ablade
            var returnObj = { Manifest: Manifest, Ablade: Ablade }
            return returnObj;

        }
        if (comand === 'getRoute') {

            var Manifest = TourData[0].Manifest

            var json = { Manifest: Manifest, ExtraData: ExtraData }

            var success = await sendData('Web_MakePolyLine', '', json) //save coords on Server
            //console.log(success);

            if (success !== 'error') {
                toast({ render: () => ( <CheckToast title='Route Speichern!' descr="Route wurde gespeichert"/> ), status: 'info', duration: 7000, isClosable: true, position: 'top', })

                _fetchRoute(Manifest);
            } else {
                toast({ render: () => ( <ErrorToast title='Route Speichern!' descr="Route konnte nicht gespeichert werden"/>), status: 'error', duration: 7000, isClosable: true, position: 'top', })
            }
        }
        if (comand === 'getOptiRoute') {

            var Manifest = TourData[0].Manifest;
            var json = { Manifest, HomeData }
            var success = await sendData('Web_CalculateOptiRoute', '', json) //Invoke Route Optimization on Server

            if (success === 'success') {
                toast({ render: () => ( <CheckToast title='Optimale Route Speichern!' descr="Route wurde gespeichert"/>), status: 'info', duration: 7000, isClosable: true, position: 'top', })
                _fetchData(Manifest, 'sort')
            } else {
                toast({ render: () => ( <ErrorToast title='Optimale Route Speichern!' descr="Route konnte nicht gespeichert werden"/>), status: 'error', duration: 7000, isClosable: true, position: 'top', })
            }
        }
        if (comand === 'setCoords') {
            
            var Tour = [...TourData]
            Tour[oldIndex].Latitude = newIndex.lat
            Tour[oldIndex].Longitude = newIndex.long
            setTourData(Tour);

            sendCoords(TourData[0].Manifest, Tour[oldIndex].AbladeID, Tour[oldIndex].Kennzeichen, Tour[oldIndex].Latitude, Tour[oldIndex].Longitude)
        }
        if (comand === 'setLCoords') {
            
            var Tour = [...TourData]

            var AIndex = newIndex;
            var LIndex = value3;
            var LieferHash = Tour[AIndex].Lieferstellen[LIndex].LieferHash

            Tour[AIndex].Lieferstellen[LIndex].M_Latitude = oldIndex.lat
            Tour[AIndex].Lieferstellen[LIndex].M_Longitude = oldIndex.long
            setTourData(Tour);

            if (LieferHash !== ''){
              return await sendLCoords( LieferHash, oldIndex.lat, oldIndex.long)
            } else {
                return false
            }
        }
        if (comand === 'InvertRoute') {

            var Manifest = TourData[0].Manifest
            var params = '?Manifest=' + Manifest 
            var success = await sendData('Web_InvertAbladeSt',params, {} )

            if (success === 'success') {
                toast({ render: () => ( <CheckToast title='Route Invertieren!' descr="Reihenfolge wurde invertiert"/> ), status: 'info', duration: 5000, isClosable: true, position: 'top', })
                _fetchData(Manifest, 'sort')
            } else {
                toast({ render: () => ( <ErrorToast title='Route Invertieren!' descr="Reihenfolge konnte nicht invertiert werden"/>), status: 'error', duration: 7000, isClosable: true, position: 'top', })
            }
        }
        if (comand === 'getDrivers') {

            var data = await sendData('Web_LoadAvailableUsers', '', {})
            if (Array.isArray(data)) {
                return data;
            } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Userdaten sind kein Array!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
        }
        if (comand === 'setNewDriver') {

            var params = '?Manifest=' + newIndex + '&Driver=' + oldIndex
            var data = await sendData('Web_AsignNewDriver', params, {})
            if (data === 'success') {
                var Manifest = { ...curentManifest }
                Manifest.Fahrer_ID = oldIndex;
                setCurentManifest(Manifest);
            }
            return data;
        }
        if (comand === 'explodeAblade') {
            var data = await sendData('Web_ExplodeAblade', '', {Manifest: oldIndex, AbladeSt: newIndex })
            if (data === 'success') {
                await _fetchAfterMovingData(oldIndex, 'sort', null, false)
            }
            return data;
        }
    }
    //depracated here
    const colorMaker = (Status) => {
        if (Status === 'complete') {
            return '#b8edc6'
        } else if (Status === null) {
            return 'azure'
        } else if (Status === 'live') {
            return '#fdd386'
        } else {
            return '#fcb1b1'
        }
    }
    function handleSelect(index) {
        if (selected !== tableData[index].Manifestnummer) {
            setSelected(tableData[index].Manifestnummer)
        } else {
            setSelected('')
        }
    }
    //depracated here
    const EditButtonHandler = () => {

        var manifIndex = tableData.findIndex((item) => item.Manifestnummer == selected)

        if (props.UserRights.tour_manage_all || props.UserRights.tour_manage) {

            return <Button m={2} colorScheme='twitter' 
            //onClick={() => [_fetchData(curentManifest.Manifestnummer, 'sort'), transformRoute(curentManifest.Manifestnummer)]}
            onClick={() => {
                searchParams.set('manifest',  selected);
                navigate(props.route + `/edit?${searchParams.toString()}`);
            }}
            >Manifest Bearbeiten</Button>

        //user can only see and edit their own tours anyway
        /*} else if (props.ActiveUser.User_ID == tableData[manifIndex].Fahrer_ID && props.UserRights.tour_manage) {

            return <Button m={2} colorScheme='twitter' 
            //onClick={() => [_fetchData(curentManifest.Manifestnummer, 'sort'), (curentManifest.Manifestnummer)]}
            onClick={() => {
                searchParams.set('manifest',  selected);
                navigate(props.route + `/edit?${searchParams.toString()}`);
            }}
            >Manifest Bearbeiten</Button>
        */
        } else {
            return <Button m={2} colorScheme='twitter' isDisabled={true}>Manifest Bearbeiten</Button>
        }
    }
    const SearchTable = () => {

    }
    return(
        <Routes>
            <Route index element={
                <ManifestSucheTable
                    searchTableCall={searchTableCall}
                    ManifestData={ManifesteData}
                    FilteredManifestData={tableData}
                    Date1={Date1} Date2={Date2}
                    selected={selected}
                    curentManifest={curentManifest}
                    PersFilterValue={PersFilterValue}
                    PersFilterData={PersFilterData}
                    ActiveUser={props.ActiveUser}
                    UserRights={props.UserRights}
                    FilterCall={FilterCall}
                    server={props.server}
                />
            }/>
            <Route path="view/*" element={
                <ViewManifest 
                    viewCall={viewCall} TourData={TourData} RouteObj={RouteObj} DriverData={DriverData}
                    curentManifest={curentManifest} ActiveUser={props.ActiveUser} server={props.server}
                    route={props.route + "/view"} 
                />
            }/>
            <Route path="edit/*" element={
                <SortManifest
                    viewCall={viewCall} TourData={TourData} TourUnset={TourUnset} RouteObj={RouteObj}
                    curentManifest={curentManifest} ActiveUser={props.ActiveUser} server={props.server} 
                    UserRights={props.UserRights} _sortierCall={_sortierCall} 
                    route={props.route + "/edit"} 
                />
            }/>
            <Route path="*" element={<Navigate to={props.route + '/'} />}/>
        </Routes>
    )
}