import React, { useState, useEffect } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button, Text } from '@chakra-ui/react'



export default function ChacraAlert(props) {

    return (

        <AlertDialog
            isOpen={props.Alert.isOpen}
            onClose={props.Alert.onClose}
            isCentered
            closeOnEsc
            closeOnOverlayClick

        >
            <AlertDialogOverlay>
                <AlertDialogContent bgColor='#eee'>
                    <AlertDialogHeader fontSize={22} fontWeight='bold'>
                        {props.Alert.header}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {props.Alert.text}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={props.Alert.onClose} colorScheme='teal' variant='outline'>
                            {props.Alert.closeBtn}
                        </Button>
                        <Button onClick={props.Alert.onAction} colorScheme='red' ml={3}>
                            {props.Alert.actBtn}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}


