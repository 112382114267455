import React from 'react';
import { SimpleGrid, Box, useToast, Flex, Text, Center, Button, Icon, Square } from '@chakra-ui/react'
import { FaUser, FaArrowLeft } from 'react-icons/fa'



export default function WebsiteHeader(props) {
    return (
        <Flex display='flex' minWidth={600} h='7vh' minH={50} backgroundColor='#f06b64' alignItems='center' justifyContent='space-between'>
            <Box display='flex'>
                <Center w={61} h={41} borderWidth={1} m={1} borderRadius={5} as='button' onClick={props.onClick}>
                    <Icon as={FaArrowLeft} color='#fff' boxSize={8} />
                </Center>
                <Text mx={3} fontWeight='bold' color='#fff' fontSize={33} noOfLines={1} >{props.Text}
                </Text>
            </Box>
            <Box display='flex' w={300} h='100%' borderLeftWidth={1} alignItems='center' justifySelf='flex-end'>

                <Square borderWidth={1} borderColor='white' size={50} borderRadius={5} ml={2} backgroundColor='gray'>



                    {props.User.User_Img !== undefined && props.User.User_Img !== '' ? (
                        <img src={props.server + '/API/icons/' + props.User.User_Img} alt="Profilbild" style={{ borderRadius: 5, objectFit: 'cover', height: 48, width: 48 }} />
                    ) : (
                        <Icon as={FaUser} color='#fff' boxSize={8} m={2} />
                    )}

                    {//console.log(typeof props.User.User_Img)
                    }


                </Square>
                <Text mx={3} fontWeight='bold' color='#fff' fontSize={22}>{props.User.User_Name}</Text>
            </Box>
        </Flex>
    );
}


