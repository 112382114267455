import React, { useState, useEffect } from 'react';
import '../../css/App.css';

import {
    SimpleGrid, Box, useToast, Flex, Text, SliderThumb, Center, IconButton, Button, Modal, Spinner,
    Table, Thead, Tbody, Tr, Th, Td, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
    Spacer, Tooltip, Icon,
} from '@chakra-ui/react'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation, useSearchParams  } from 'react-router-dom';

import TableHead from '../../GlobalComponents/TableHead';
import WebsiteHeader from '../../GlobalComponents/WebsiteHeader';
import DatePicker from 'react-date-picker';
import { ErrorToast, WarnToast, CheckToast } from '../../GlobalComponents/CustomToast';
import { ManifestFilterComp } from '../../GlobalComponents/ManifestFilterComp';
import { FaRegCalendarTimes } from 'react-icons/fa';
import BaseModal from '../../GlobalComponents/BaseModal';

export default function MoveAblade(props) {
    //const [editMap, setEditMap] = useState(true);
    const [selectedAblade, setSelectedAblade] = useState([]);
    const [curentManifest, setCurentManifest] = useState({});

    const [Manifeste, setManifeste] = useState([]);

    const [TargetAbladeSt, setTargetAbladeSt] = useState([]);
    const [loadAblade, setLoadAblade] = useState(false);
    const [Date1, setDate1] = useState(new Date());
    const [Date2, setDate2] = useState(new Date());
    const [loading, setLoading] = useState(false);

    const [tableData, setTableData] = useState([]); //filtered Manifest Collection
    const [PersFilterValue, setPersFilterValue] = useState({Tour: '', Team: '', Driver: '', Manifest: ''});
    const [PersFilterData, setPersFilterData] = useState({Tours: [], Teams: [], Drivers: [] });

    const [Drivers, setDrivers] = useState([]);
    const [curentDriver, setCurentDriver] = useState({});

    const [DriverMod, setDriverMod] = useState(false);
    const [ConfirmMod, setConfirmMod] = useState(false);

    const toast = useToast();
    const toastIdRef = React.useRef();
    const SortRef = React.useRef();

    const navigate = useNavigate(); //navigate to a certain path
    const [searchParams, setSearchParams] = useSearchParams(); //read and set query-params

    useEffect(() => {
        ////console.log(props.AbladeSt)
        //AvailableTours()
        initSearchTours();
        readDefaultQuery();
    }, [props.TourData])

    //-w Manifest Filter Funktions

    const sortingReciever = (comand, value1, value2) => {
        if (SortRef.current) {
        SortRef.current.Reciever(comand, value1, value2);
        }
    };
    async function FilterCall(comand, value1, value2, value3) {
        switch (comand) {
            case 'filtered':
                setTableData( value1 );
            break;
            case 'setPersData':
                setPersFilterData( value1 );
            break;
            case 'newSelected':
                PersValueSetter(value1, value2);
            break;
            default:
                return;
          }
    }
    const readDefaultQuery = () => { //parses default query param

		const Ablade = searchParams.get('ablade');

		if(Ablade !== null && Ablade.length === 12){
			var Ablade_Idx = props.TourData.findIndex((item) => item.AbladeID == Ablade);
            if(Ablade_Idx !== -1){
                var AbladeSt = props.TourData[Ablade_Idx];
                selectItem(AbladeSt.AbladeID, AbladeSt.Status, Ablade_Idx)
            }
		}	
	}
    function reloadFilters() {
        
        //if (SubSection === 'select') {
        //    sortingReciever('setFilter', PersFilterValue);
        //}
    }
    async function PersValueSetter(key, value) {
        var oldData = PersFilterValue;
        oldData[key] = value;
        setPersFilterValue(PersFilterValue);
    }
    async function _getsetPrevDay(){

        var date = new Date()
        const day = date.getDay(); 
        const daysToSubtract = day === 0 ? 2 : day === 1 ? 3 : 1; 
        const previousWorkday = new Date(date); 
        previousWorkday.setDate(date.getDate() - daysToSubtract); 
        setDate1(previousWorkday);
        setDate2(date);
        return previousWorkday;
    }
    async function initSearchTours(){

        var previous = await _getsetPrevDay();
        var today = new Date();

        var start = previous.getFullYear() + '-' + (previous.getMonth() + 1) + '-' + previous.getDate()
        var end = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()

        AvailableTours(start, end);
    } 

    async function AvailableTours(Day1 = null, Day2 = null) {

        if (Day1 === null) { var start = Date1.getFullYear() + '-' + (Date1.getMonth() + 1) + '-' + Date1.getDate() } else { var start = Day1 }
        if (Day2 === null) { var end = Date2.getFullYear() + '-' + (Date2.getMonth() + 1) + '-' + Date2.getDate() } else { var end = Day2 }

        
        var Results = await props.MoveCall('getAvailableTours', end, start)

        var Manifeste = Results.Manifeste;

        if (Array.isArray(Manifeste)) {

            /*var index = Manifeste.findIndex(x => x.Manifestnummer === props.curentManifest.Manifestnummer);
            if (index > -1) {
                Manifeste.splice(index, 1);
            } now removed via API*/
            setManifeste(Manifeste);
            setTableData(Manifeste);
            sortingReciever('makeFilter', Results);
        }
        
    }
    async function TargetAblade(Manifest) {
        setLoadAblade(true);

        var Ablade = await props.MoveCall('getTargetAblade', Manifest)

        setTargetAbladeSt(Ablade);
        setLoadAblade(false);
    }
    async function openDriverSelect() {
        var Drivers = await props.MoveCall('getDrivers');
        if (Array.isArray(Drivers)) {
            setDrivers(Drivers);
            setDriverMod(true);
        }
    }
    async function finalizeHandler() {

        if (selectedAblade.length !== 0) {

            var Abladestellen = selectedAblade.map(item => item.AbladeID);

            if (curentDriver.hasOwnProperty('User_Name')) { //set Params

                var body = { Abladestellen: Abladestellen, ManifestNew: "new", ManifestOld: props.curentManifest.Manifestnummer, Driver: curentDriver.User_ID, Type: "Web" }

            } else if (curentManifest.hasOwnProperty('Manifestnummer')) { //set Params

                var body = { Abladestellen: Abladestellen, ManifestNew: curentManifest.Manifestnummer, ManifestOld: props.curentManifest.Manifestnummer, Driver: 0, Type: "Web" }

            }
            setConfirmMod(false);
            setLoading(true);

            var finishResult = await props.MoveCall('MoveAbladestellen', body)

            if (finishResult.move == 'success') { //Display both edited Manifeste

                setSelectedAblade([]);
                setLoading(false);
                DisplayMovementResult(props.curentManifest.Manifestnummer, finishResult.Manifest)

                toast({ render: () => ( <CheckToast title='Abladestelle/n Verschoben!' descr="Die ausgewählten Abladestellen wurden erfolgreich in ein neues Manifest verschoben!"/>), status: 'info', duration: 5000, isClosable: true, position: 'top-right', })

            } else if (finishResult === 'denied') { 
                setLoading(false);
                return;}
            else {

                var Descr = await MoveAbladeAlert(finishResult);

                setLoading(false);
                toast({ render: () => ( <WarnToast title='Abladestellen Transfer fehlgeschlagen!' descr={Descr}/>), status: 'warning', duration: 10000, isClosable: true, position: 'top-right' })
            }
        }
    }
    async function MoveAbladeAlert(Case) {

        switch (Case) {
            case 'error_source':
              return 'Das Ursprungs-Manifest ist bereits aktiv.';
            case 'error_ablade':
              return 'Keine der gewählten Abladestellen kann verschoben werden.';
            case 'error_create':
              return 'Fehler beim Erstellen eines neuen Manifests.';
            case 'error_live':
              return 'Das Ziel-Manifest ist aktiv, oder kann keine Abladestellen aufnehmen.';
          }
    }
    async function DisplayMovementResult(OldManifest, NewManifest) {

        console.log(OldManifest, NewManifest)

        setLoadAblade(true);

        var Results = await props.MoveCall('DisplayMovementResult', OldManifest, NewManifest)

        setManifeste([Results.Manifest]);
        setCurentManifest(Results.Manifest);
        setTargetAbladeSt(Results.Ablade);

        setLoadAblade(false);
    }
    async function TargetAblade(Manifest) {
        setLoadAblade(true);

        var Ablade = await props.MoveCall('getTargetAblade', Manifest)

        setTargetAbladeSt(Ablade);
        setLoadAblade(false);
    }

    const columns = [
        {
            label: 'Tour', accessor: 'Tour', sortable: true, width: 100,
        },
        {
            label: 'Fahrer', accessor: 'Fahrer_Name', sortable: true, width: 100,
        },
        {
            label: 'ManifestNr', accessor: 'Manifestnummer', sortable: true, width: 100,
        },
        {
            label: 'AbladeSt', accessor: 'Ablade_Anzahl', sortable: true, width: 40,
        },
        {
            label: 'LieferSt', accessor: 'Liefer_Anzahl', sortable: true, width: 40,
        },
        {
            label: 'Pakete', accessor: 'Pakete_Anzahl', sortable: true, width: 40,
        },
        {
            label: 'Papier', accessor: 'Papier_Anzahl', sortable: true, width: 40,
        },
        {
            label: 'Datum', accessor: 'Datum', sortable: true, width: 40,
        },
    ]
    const ManifestDisplay = [
        { label: 'Fahrer:', ident: 'Fahrer_Name' },
        { label: 'Tour:', ident: 'Tour' },
        { label: 'Abladestellen:', ident: 'Ablade_Anzahl' },
        { label: 'Lieferstellen:', ident: 'Liefer_Anzahl' },
        { label: 'Pakete:', ident: 'Pakete_Anzahl' },
        { label: 'Papier:', ident: 'Papier_Anzahl' },
        { label: 'Datum:', ident: 'Datum' },
        { label: 'Status:', ident: 'Status' },
    ]
    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...Manifeste].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setManifeste(sorted);
        }
    };
    function DriverSelect(user) {

        setDriverMod(false)
        setCurentDriver(user)
        setConfirmMod(true)
    }

    function selectItem(AbladeID, Status, Index) {
        if (Status !== 'done') {

            var selected = [...selectedAblade];

            var found = selected.findIndex((item) => item.AbladeID === AbladeID)
            if (found === -1) {
                selected.push({ AbladeID: AbladeID, Ix: Index })
                console.log('pushed')
            } else {
                selected.splice(found, 1)
                console.log('spliced')
            }
            setSelectedAblade(selected)

        }
    }
    function handleSelect(index) {
        //console.log(Manifeste[index])

        if (curentManifest.Manifestnummer === tableData[index].Manifestnummer) {
            setCurentManifest({})
            setTargetAbladeSt([]);
        } else {
            setCurentManifest(tableData[index]);
            TargetAblade(tableData[index].Manifestnummer);
        }
    }
    const makeBackgroundColor = (item) => {

        if (item.Status === 'done') {
            return { backgroundColor: "#fcb1b1" }
        } else if (selectedAblade.some(obj => obj.AbladeID === item.AbladeID)) {
            return { backgroundColor: "#ffdba6" }
        } else {
            return { backgroundColor: "white" }
        }  

    }
    const colorMaker = (Status) => {
        if (Status === null) {
            return '#fcb1b1'
        } else if (Status === 'live') {
            return '#fdd386'
        } else {
            return 'azure'
        }
    }

    return (
        <Flex flexDir='column' >
            <WebsiteHeader Text='TourPortal / Manifest Sortieren / Abladestellen Verschieben' onClick={() => props.MoveCall('close')} server={props.server} User={props.ActiveUser} overFlow='hidden' />
            <Flex height='93vh' backgroundColor='#ccc'>
                <Flex flex={4} padding={1} flexDirection='column'>

                    <Box display='flex' flex={1} flexDirection='column' borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff' padding={2} overflow='auto'>

                        <Text fontSize={25} fontWeight={'bold'}>Aktuelles Manifest: {props.curentManifest.Manifestnummer}</Text>

                        <Flex flex={1} m={1} flexDirection='column' >

                            {ManifestDisplay.map((item) => {
                                return (
                                    <Flex my={-1}>
                                        <Flex width='140px'>
                                            <Text fontSize={20} >{item.label}</Text>
                                        </Flex>
                                        <Flex >
                                            <Text fontSize={20} >{props.curentManifest[item.ident]}</Text>
                                        </Flex>
                                    </Flex>
                                )
                            })}
                        </Flex>
                    </Box>

                    <Box display='flex' flex={3} flexDirection='column' borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff' padding={2} mt={1} overflow='auto'>

                        <Text fontSize={25} fontWeight={'bold'}>Abladestellen zum Verschieben:</Text>

                        <Flex flex={1} flexDirection='column' overflow='auto'>
                            {props.TourData.map((item, index) => {
                                return (
                                    <Flex bg='#fff' marginTop={1} mx={1} borderWidth={1} borderRadius={3} borderColor='#000' flex='row' key={item.AbladeID} justifyContent='space-between' h='52px' borderStyle={ item.Status === 'done' ? 'dashed' : 'solid' }
                                        onClick={() => selectItem(item.AbladeID, item.Status, index)}
                                        style={makeBackgroundColor(item)}
                                    >
                                        <Flex flex='row'>
                                            <Center bg='#9ace93' minW={50} h={50} mr={5} text-align='center'>
                                                <Text fontSize={32} fontWeight='bold'>{index + 1}</Text>
                                            </Center>
                                            <Box flex='4' textAlign='left' >
                                                <Text fontWeight='bold' >{props.ShowCustomer ? ('> ' + item.Lieferstellen[0].Name1 +' | '):(null)}{item.Straße} | {item.PLZ} {item.Ort} </Text>
                                                <Text>{item.Kennzeichen} || {item.Bezeichnung} || {item.Liefer_Anzahl} Lieferstelle/n || {item.Pakete_Anzahl} Paket/e || {item.Papier_Anzahl}x Papier </Text>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                )
                            })}
                        </Flex>

                    </Box>

                </Flex>
                <Flex flex={5} p={1} pl={0} flexDirection='column' overflow='auto' >

                    <Box flex={0.15} display='flex' justifyContent='start' borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff' padding={2} mb={1} >
                        <Tooltip label="Bitte zuerst Abladestellen auswählen!" isDisabled={selectedAblade.length !== 0} shouldWrapChildren placement='top' hasArrow arrowSize={10}>
                            <Button mx={2} onClick={() => setConfirmMod(true)} colorScheme='twitter'
                                isDisabled={selectedAblade.length === 0 || !curentManifest.hasOwnProperty('Manifestnummer')}>Abladestellen Verschieben</Button>
                        </Tooltip>
                        <Tooltip label="Bitte zuerst Abladestellen auswählen!" isDisabled={selectedAblade.length !== 0} shouldWrapChildren placement='top' hasArrow arrowSize={10}>
                            <Button mx={2} onClick={() => openDriverSelect(true)} colorScheme='twitter'
                                isDisabled={selectedAblade.length === 0}>Neues Manifest erstellen</Button>
                        </Tooltip>
                    </Box>

                    <Box flex={2} display='flex' flexDirection='column' borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff' padding={2} >

                        <Text fontSize={25} fontWeight={'bold'}>Verfügbare Manifeste:</Text>

                        <Flex alignItems='center' flexWrap={'wrap'}>

                            <Flex m={2} width='530px' height='40px' border='0px solid black' alignItems='center' justifyContent='space-between' borderRadius={6} bg='#1DA1F2'>
                                <IconButton bg='#1DA1F2' color='#fff' width='40px' icon={<Icon as={FaRegCalendarTimes} color='#fff' boxSize={25}/>} 
                                onClick={() => _getsetPrevDay()} colorScheme='twitter' borderRadius='5px 0px 0px 5px'/>
                                <Text  color='#fff' fontWeight='semibold' fontSize={17} >von:</Text>
                                <DatePicker onChange={setDate1} value={Date1} format='dd.MM.y' border='0px solid black'/>
                                <Text  color='#fff' fontWeight='semibold' fontSize={17} >bis:</Text>
                                <DatePicker onChange={setDate2} value={Date2} format='dd.MM.y' border='0px solid black'/>
                                <Button onClick={() => AvailableTours()} colorScheme='twitter' borderRadius='0px 5px 5px 0px'>Suche</Button>
                            </Flex>
                        
                            <ManifestFilterComp ref={SortRef} FilterCall={FilterCall} ManifestArrayOrigin={Manifeste} PersFilterData={PersFilterData} TeamFilter={ props.UserRights.tour_manage_all || PersFilterData.Teams.length > 1 }/>

                        </Flex>

                        <Flex overflow='auto' maxHeight='18.5vh'
                        >
                            <Table size="small" >
                                <TableHead {...{ columns, handleSorting }} />
                                <Tbody>
                                    {tableData.map((row, index) => {
                                        return (

                                            <Tr key={row.Manifestnummer} onClick={() => handleSelect(index)}
                                                bg={() => colorMaker(row.Status)}
                                                border={curentManifest.Manifestnummer === row.Manifestnummer ? '3px dashed #0060d5' : null}>
                                                {columns.map(({ accessor }) => {
                                                    const tData = row[accessor];
                                                    return (<React.Fragment key={accessor}>
                                                        <Td textAlign="center" borderWidth={1} >
                                                            <Text noOfLines={1}>{tData}</Text>
                                                        </Td>
                                                        <Td className="columnResizer" />
                                                    </React.Fragment>
                                                    );
                                                })}
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </Flex>

                    </Box>

                    <Box flex={2} display='flex' flexDirection='column' borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff' padding={2} overflow='auto' mt={1}>
                        <Text fontSize={25} fontWeight={'bold'}>Vorhandene Abladestellen:</Text>
                        {
                            loadAblade ? (
                                <Flex flex={1} justifyContent='center' alignItems='center'>
                                    <Spinner thickness='6px' speed='1s' emptyColor='gray.200' color='blue.500' size='xl' />
                                </Flex>
                            ) : (
                                <Flex flex={1} flexDirection='column' overflow='auto'>
                                    {TargetAbladeSt.map((item, index) => {
                                        return (
                                            <Flex bg='#fff' marginTop={1} mx={1} borderWidth={1} borderRadius={3} borderColor='#000' flex='row' key={item.AbladeID} justifyContent='space-between' h='52px'>
                                                <Flex flex='row'>
                                                    <Center bg='#9ace93' minW={50} h={50} mr={5} text-align='center'>
                                                        <Text fontSize={32} fontWeight='bold'>{index + 1}</Text>
                                                    </Center>
                                                    <Box flex='4' textAlign='left' >
                                                        <Text fontWeight='bold' >
                                                        {item.Strasse} | {item.PLZ} {item.Ort} </Text>
                                                        <Text>{item.Kennzeichen} || {item.Bezeichnung} || {item.Liefer_Anzahl} Lieferstelle/n || {item.Pakete_Anzahl} Paket/e || {item.Papier_Anzahl}x Papier </Text>
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        )
                                    })}
                                </Flex>
                            )}
                    </Box>
                </Flex>
            </Flex >
            <BaseModal isOpen={DriverMod} onClose={() => setDriverMod(false)} 
                style={{ height: '40vh', width: '400px', position: 'absolute', right: '10vw', top: 75, alignItems: 'center', overflow: 'auto', padding: 3, borderWidth: 1, borderRadius: 5 }}
                >

                    <Text fontSize={20} fontWeight='bold'>Bitte wähle einen Nutzer dem die neue Tour zugewiesen werden soll:</Text>
                    {Drivers.map((user, index) => {
                        return (

                            <Button key={user.User_ID} width='95%' alignItems='center' m={1} borderColor='#666' variant='outline'
                                borderRadius={5} onClick={() => DriverSelect(user)}
                            >

                                <Text fontSize={20} >{user.User_Name}</Text>
                                <Spacer />
                                <Text fontSize={17} mx={2}>{user.Role_Name}</Text>

                            </Button>
                        )
                    })}
            </BaseModal>
            <BaseModal isOpen={ConfirmMod} onClose={() => setConfirmMod(false)} 
                style={{ display: 'flex', flexDirection: 'column', height: '40vh', width: '50vw', alignItems: 'flex-start', padding: 5, marginTop: 150 }}
                >

                    <Text fontSize={20} fontWeight='bold'>Die folgenden Abladestellen werden</Text>
                    {curentDriver.hasOwnProperty('User_Name') ? (
                        <Text fontSize={20} fontWeight='bold'>in eine neue Tour für Nutzer {curentDriver.User_Name} verschoben:</Text>
                    ) : (
                        <Text fontSize={20} fontWeight='bold'>in die Tour {curentManifest.Manifestnummer} verschoben:</Text>
                    )}

                    <Box flex={1} width='100%' minHeight='30px' overflow='auto' my={2} >
                        {selectedAblade.map((item, index) => {
                            return (
                                <Flex backgroundColor='#eee' px={1} my={2}>
                                    <Text>[ {props.TourData[item.Ix].Kennzeichen} ]  {props.TourData[item.Ix].Straße} {props.TourData[item.Ix].PLZ} {props.TourData[item.Ix].Ort}</Text>
                                </Flex>
                            )
                        })}
                    </Box>
                    <Flex width='100%' justifyContent='space-around'>
                        <Button mx={2} onClick={() => finalizeHandler()} colorScheme='twitter'>Bestätigen</Button>
                        <Button mx={2} onClick={() => [setConfirmMod(false), setCurentDriver({})]} colorScheme='twitter'>Abbrechen</Button>
                    </Flex>
            </BaseModal>
            <BaseModal isOpen={loading} 
            style={{display: 'flex', flexDirection: 'column', height: '200px', minHeight: '200px', width: '30vw', alignItems: 'center', justifyContent: 'space-evenly', padding: 5  }} >
    
                    <Text fontSize={20} >Abladestellen werden verschoben...</Text>
                    <Spinner thickness='6px' speed='1s' emptyColor='gray.200' color='blue.500' size='xl' />

            </BaseModal>
        </Flex >
    )
}