import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { Box, Text, Wrap, Accordion, Flex, Center, Button, IconButton, useToast } from '@chakra-ui/react';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from '@dnd-kit/utilities';

const Item = (({value, index}) => {
  return (
      <Flex bg='#fff' m={1} borderWidth={1} borderRadius={3} borderColor='#000' flex='row' justifyContent='space-between' 
      //backgroundColor={value.Status !== 'D' ? (null) : ('#bbb') }
      >
          <Flex flex='row'>
            <Center bg='orange' w={50} mr={5} text-align='center'>
                  <Text fontSize={32} fontWeight='bold'>{index + 1}</Text>
              </Center>
              <Box flex='4' textAlign='left'>
                  <Text fontWeight='bold'>{value.Name1}; {value.Name2}; {value.Name3}; {value.Name4}</Text>
                  <Text bgColor='white'>{value.LieferID}: {value.Strasse} {value.PLZ} {value.Ort} || Tel: {value.Telefon}</Text>
              </Box>
          </Flex>
      </Flex>
  )
});

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item id={props.key} index={props.index} value={props.value} />
    </div>);
}

export default function LieferSortList(props){

  const [items, setItems] = useState([]);
  const [DataObject, setDataObject] = useState({});

  useEffect(() => {

    const resultObject = [...props.Lieferstellen ].reduce((accumulator, currentObject) => {
      accumulator[currentObject.LieferID] = currentObject;
      return accumulator;
    }, {});

    setDataObject(resultObject);

    var ItemArray = props.Lieferstellen.map(item => (item.LieferID));
    
    setItems(ItemArray);

  }, [props.Lieferstellen]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      setItems((items) => {
          return arrayMove(items, oldIndex, newIndex);
      });

      props._LieferSort( oldIndex, newIndex);
    }
  };

  return(
    <div style={{ padding: '20px' }}>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={items}
          strategy={verticalListSortingStrategy}
        >
          {items.map((id, index) => (
            <SortableItem key={id} id={id} index={index} value={DataObject[id]} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  )
};

