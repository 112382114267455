import React, { useState } from "react";
import { Button, Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text } from '@chakra-ui/react'
import ColumnResizer from "react-table-column-resizer";
import '../css/App.css';

const TableHead = ({ columns, handleSorting }) => {
    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("asc");

    const handleSortingChange = (accessor) => {
        const sortOrder =
            accessor === sortField && order === "asc" ? "desc" : "asc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };

    return (
        <Thead position="sticky" top={0} zIndex={1}>
            <Tr>
                {columns.map(({ label, accessor, sortable, width }) => {
                    const cl = sortable
                        ? sortField && sortField === accessor && order === "asc"
                            ? "up"
                            : sortField && sortField === accessor && order === "desc"
                                ? "down"
                                : "default"
                        : "";
                    return (
                        <React.Fragment key={accessor}>
                            <Th
                                onClick={sortable ? () => handleSortingChange(accessor) : null}
                                className={cl}
                                bg='#e6e6e6'
                                //color='#ddd'
                                textAlign="left"
                                borderWidth='1.5px'
                                borderColor='#e6e6e6'
                                pl={2}
                            >
                                 <div style={beforeStyle}></div>
                                <Text fontSize='13px' noOfLines={1} color='#222' >{label}</Text>
                                <div style={afterStyle}></div>
                            </Th>
                            <ColumnResizer className="columnResizer"/>
                        </React.Fragment>
                    );
                })}
            </Tr>
        </Thead>
    );
};
const beforeAfterStyles = {
    content: '',
    position: 'absolute',
    left: 0,
    width: '100%',
  };
  
  const beforeStyle = {
    ...beforeAfterStyles,
    top: '-10px',
    borderTopWidth: '10px',
    borderTopColor: 'white',
    width: '100%',
  };
  
  const afterStyle = {
    ...beforeAfterStyles,
    bottom: '-2px',
    borderBottomWidth: '2px',
    borderBottomColor: '#222',
  };

export default TableHead;