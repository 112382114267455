import React, { useState, useEffect, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Icon, Flex, useToast, Center, Input, Select, InputGroup, InputRightElement, VStack, Tooltip, Spacer, Alert, Square } from '@chakra-ui/react'

import {AiOutlineClose} from 'react-icons/ai'
import { BsExclamationOctagonFill, BsFillExclamationTriangleFill,BsExclamationCircleFill, BsCheckCircleFill } from 'react-icons/bs'


export function ErrorToast(props) {

    const toast = useToast();
    const closeToast = () => { toast.closeAll();};

    return (
        <Box p={1} bg='#eee' borderRadius={10}>

            <Box p={2} bg='#fff' borderWidth={2} borderRadius={8} borderColor='red' display='flex' flexDirection='row' flex={1}>

                <Icon as={BsExclamationOctagonFill} color='red' boxSize='30px' mr={2} />
                <Box display='flex' flexDirection='column'>
                    <Text color='red' fontSize={17} fontWeight='bold' >{props.title}</Text>
                    <Text color='red' fontSize={17}>{props.descr}</Text>
                </Box>
                <Box display='flex' alignItems='top' height='60px' >
                    <Icon as={AiOutlineClose} color='#666' boxSize='20px' onClick={closeToast}/>
                </Box>
            </Box>
        </Box>
    )
}

export function WarnToast(props) {

    const toast = useToast();
    const closeToast = () => { toast.closeAll();};

    return (
        <Box p={1} bg='#eee' borderRadius={10}  >
            <Box p={2} bg='#fff' borderWidth={2} borderRadius={8} borderColor='#e18700' display='flex' flex={1} alignItems='space-evenly'>
                <Icon as={BsFillExclamationTriangleFill} color='#e18700' boxSize='30px' mr={2} />
                <Box>
                    <Text color='#e18700' fontSize={17} fontWeight='bold' >{props.title}</Text>
                    <Text color='#e18700' fontSize={17}>{props.descr}</Text>
                </Box>
                <Box display='flex' alignItems='top' height='60px' ml={-3}>
                    <Icon as={AiOutlineClose} color='#666' boxSize='20px' onClick={closeToast}/>
                </Box>
            </Box>
        </Box>
    )
}

export function InfoToast(props) {

    const toast = useToast();
    const closeToast = () => { toast.closeAll();};

    return (
        <Box p={1} bg='#eee' borderRadius={10}  >
            <Box p={2} bg='#fff' borderWidth={2} borderRadius={8} borderColor='#528b96' display='flex' alignItems='center'>
                <Icon as={BsExclamationCircleFill} color='#528b96' boxSize='30px' mr={2} />
                <Box>
                    <Text color='#528b96' fontSize={17} fontWeight='bold' >{props.title}</Text>
                    <Text color='#528b96' fontSize={17}>{props.descr}</Text>
                </Box>
                <Box display='flex' alignItems='top' height='60px' ml={-3}>
                    <Icon as={AiOutlineClose} color='#666' boxSize='20px' onClick={closeToast}/>
                </Box>
            </Box>
        </Box>
    )
}

export function CheckToast(props) {

    const toast = useToast();
    const closeToast = () => { toast.closeAll();};

    return (
        <Box p={1} bg='#eee' borderRadius={10}  >
            <Box p={2} bg='#fff' borderWidth={2} borderRadius={8} borderColor='#2bb58e' display='flex' alignItems='center'>
                <Icon as={BsCheckCircleFill} color='#2bb58e' boxSize='30px' mr={2} />
                <Box>
                    <Text color='#2bb58e' fontSize={17} fontWeight='bold' >{props.title}</Text>
                    <Text color='#2bb58e' fontSize={17}>{props.descr}</Text>
                </Box>
                <Box display='flex' alignItems='top' height='60px' ml={-3}>
                    <Icon as={AiOutlineClose} color='#666' boxSize='20px' onClick={closeToast}/>
                </Box>
            </Box>
        </Box>
    )
}